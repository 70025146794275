import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { HttpClient } from '@angular/common/http';
  /**
   * injecting
  */
@Injectable({
  providedIn: 'root'
})
export class PricingService {

  
  constructor(private apiService: ApiServiceService, private http: HttpClient) { }
  insertAndUpdate(data) {
    return this.apiService.post('/api/OrderSummary/insertAndUpdate', data);
  }
  FeatureComparision(data) {
    return this.apiService.post('/api/Pricing/FetchFeatureFlaggingDetails', data);
  }
  CountryList(data) {
    return this.apiService.post('/api/Pricing/CountryList', data);
  }
  StateList(data) {
    return this.apiService.post('/api/Pricing/StateList', data);
  }
  listPlans(data) {
    return this.apiService.post('/api/Pricing/listPlans', data);
  }
  getSubscriptionDetail(data) {
    return this.apiService.post('/api/Pricing/getSubscriptionDetail', data);
  }
  pricingRange(data) {
    return this.apiService.post('/api/Pricing/pricingRange', data);
  }
  listPlansPrice(data) {
    return this.apiService.post('/api/Pricing/listPlansPrices', data);
  }
  insertAuditLog(data) {
    return this.apiService.post('/api/Dashboard/insertAuditLog', data);
  }
}
