import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class OrderSummaryService {

  constructor(private apiService: ApiServiceService) { }
  priceDetail(data){
    return this.apiService.post('/api/Pricing/PlanDetails', data);

   }
   GetPlanList(data) {
    return this.apiService.postCockpitFeature("/api/Admin/GetPlanList", data);
  }
  insertAuditLog(data) {
    return this.apiService.post('/api/Dashboard/insertAuditLog', data);
  }
  updateCustomerDetails(data) {
    return this.apiService.post('/api/OrderSummary/updateCustomerDetails', data);
  }
  updateCustomerBillingDetails(data) {
    return this.apiService.post('/api/OrderSummary/updateCustomerBillingDetails', data);
  }
  notifysend(data) {
    return this.apiService.postFeatured('/api/Notification/UserSendMessage', data);
  }
  insertAndUpdate(data) {
    return this.apiService.post('/api/OrderSummary/insertAndUpdate', data);
  }
  CountryList(data) {
    return this.apiService.post('/api/Pricing/CountryList', data);
  }
  StateList(data) {
    return this.apiService.post('/api/Pricing/StateList', data);
  }
  BillingDetails(data) {
    return this.apiService.post('/api/Dashboard/BillingDetails', data);
  }
  listOfCards(data) {
    return this.apiService.post('/api/Dashboard/listOfCards', data);
  }
  retrieveCardInfo(data) {
    return this.apiService.post('/api/Dashboard/retrieveCardInfo', data);
  }
  createHostedPage(data) {
    return this.apiService.post('/api/OrderSummary/createHostedPage', data);
  }
  createHostedPageForNewItems(data) {
    return this.apiService.post('/api/OrderSummary/createHostedPageForNewItems', data);
  }
  createHostedPageForCollectNow(data) {
    return this.apiService.post('/api/OrderSummary/createHostedPageForCollectNow', data);
  }
  planChange(data) {
    return this.apiService.post('/api/Pricing/planChange', data);
  }
  insertOrder(data) {
    return this.apiService.post('/api/Dashboard/setOrder', data);
  }
  getOrder(data) {
    return this.apiService.post('/api/Dashboard/getOrder', data);
  }
  deleteCard(data) {
    return this.apiService.post('/api/Dashboard/deleteCard', data);
  }
  updateCradDetails(data) {
    return this.apiService.post('/api/Dashboard/updateCradDetails', data);
  }
  getSubscriptionDetail(data) {
    return this.apiService.post('/api/Pricing/getSubscriptionDetail', data);
  }
  insertCustomerId(data) {
    return this.apiService.post('/api/SignIn/sendCustomerID', data)
  }
  insertSlack(data) {
    return this.apiService.postFeatured('/api/Workspace/SlackMessagesend', {params : {...data}})
  }
  getUserProfileDetailsService(data) {
    return this.apiService.postFeatured('/api/UserSettings/GetUserProfileDetails', data)
  }
  getSecondsData(data) {
    return this.apiService.post('/api/OrderSummary/GetTimeSecond', data)
  }
}
