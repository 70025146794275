import { Injectable } from '@angular/core';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SharedService } from '../shared.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /**
 * constructor function
 */
  constructor(public auth: AuthService, private sharedService: SharedService) {}
  /**
 * error response handler
 */
  errorMessage;
  /**
   * token interceptor for error handling and checking network connectivity
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authReq = request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${this.auth.getToken()}`
      ),
    });
    // if(authReq){
    //   this.sharedService.refreshtoken.next();
    // }
    if (!window.navigator.onLine) {
      // if there is no internet, throw a HttpErrorResponse error
      // since an error is thrown, the function will terminate here
      return Observable.throw(
        new HttpErrorResponse({ error: 'Internet is required.' })
      );
    } else {
      return next.handle(authReq).pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          if (error.error instanceof ErrorEvent) {
            this.errorMessage = error.status;
            console.log('client-side error', error.status);
          } else {
            this.errorMessage = error.status;
            console.log('server-side error', error.status);
            if (error.status == 401) {
              this.sharedService.refreshtoken.next();
            }
          }
          return throwError(errorMessage);
        })
      );
    }
  }
}
