import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private apiService:ApiServiceService, private http:HttpClient) { }

  insertAuditLog(data) {
    return this.apiService.post('/api/Dashboard/insertAuditLog', data);
  }
  WSSuspensationAndDeletion_Paid() {
    return this.apiService.postFeatured('/api/WSSuspensationAndDeletion/WSSuspensationAndDeletion_Paid', {});
  }
  GetBillingDashboardCurrnetUsage(data) {
    return this.apiService.post('/api/Dashboard/GetBillingDashboardCurrnetUsage', data);
  }
  dashboardBillingDetails(data) {
    return this.apiService.post('/api/Dashboard/BillingDetails', data);
  }
  updateCustomerDetails(data) {
    return this.apiService.post('/api/OrderSummary/updateCustomerDetails', data);
  }
  wsBillingSub(data) {
    return this.apiService.postFeatured('/api/Common/WSBillingSub', data);
  }
  insertAndUpdate(data) {
    return this.apiService.post('/api/OrderSummary/insertAndUpdate', data);
  }
  CountryList(data) {
    return this.apiService.post('/api/Pricing/CountryList', data);
  }
  StateList(data) {
    return this.apiService.post('/api/Pricing/StateList', data);
  }
  BillingDetails(data) {
    return this.apiService.post('/api/Dashboard/BillingDetails', data);
  }
  listOfCards(data) {
    return this.apiService.post('/api/Dashboard/listOfCards', data);
  }
  retrieveCardInfo(data) {
    return this.apiService.post('/api/Dashboard/retrieveCardInfo', data);
  }
  createHostedPage(data) {
    return this.apiService.post('/api/OrderSummary/createHostedPage', data);
  }
  planChange(data) {
    return this.apiService.post('/api/Pricing/planChange', data);
  }
  deleteCard(data) {
    return this.apiService.post('/api/Dashboard/deleteCard', data);
  }
  updateCradDetails(data) {
    return this.apiService.post('/api/Dashboard/updateCradDetails', data);
  }
  getSubscriptionDetail(data) {
    return this.apiService.post('/api/Pricing/getSubscriptionDetail', data);
  }
  retrieveInvoiceDetails(data) {
    return this.apiService.post('/api/Dashboard/retrieveInvoiceDetails', data);
  }
  DownloadLastInvoice(data) {
    return this.apiService.post('/api/Dashboard/DownloadLastInvoice', data);
  }
  GetInvoiceList(data) {
    return this.apiService.postFeatured('/api/InvoiceMaster/GetInvoiceList', data);
  }
  GetLastInvoiceDetail(data) {
    return this.apiService.post('/api/Dashboard/GetLastInvoiceDetail', data);
  }
  subscriptionDetails(data) {
    return this.apiService.post('/api/Dashboard/subscriptionDetails', data);
  }

  billingOrderDetails(data){
    return this.apiService.post('/api/GetBillingDashboard/BillingOrderList', data);
  } 
  billingInvoiceDetails(data){
    return this.apiService.post('/api/GetBillingDashboard/BillingInvoiceList', data);
  }
  wsSubscriptionDetailsService(data) {
    return this.apiService.postFeatured('/api/Workspace/SubscriptionDetails', data);
  }
  wsSubscriptionDetail(data) {
    return this.apiService.postFeatured('/api/GetDashboardCount/GetDashboardCount', data);
  }
  zohoCreateSubscription(data) {
    return this.apiService.post('/api/SignIn/createSubscription', data)
  }
  changeTermEnd(data) {
    return this.apiService.post('/api/SignIn/changeTermEnd', data)
  }
  createUsage(data) {
    return this.apiService.post('/api/Dashboard/createUsage', data)
  }
  getDailyInvoice(data) {
    return this.apiService.post('/api/Dashboard/dailyInvoiceList', data)
  }
  createSession(data) {
    return this.apiService.post('/api/OrderSummary/createSession', data)
  }
  managePayment(data) {
  return this.apiService.post('/api/OrderSummary/managePayment', data)
  }
  viewDocsAuditLog(data) {
    return this.apiService.postFeatured('/api/Auditlog/InsertAuditLog', data);
  }
  workspaceDetails(data) {
    return this.apiService.postCockpitFeature('/api/Admin/WorkSpaceDetail', data);
  }
  GetFeaturePlanList(data) {
    return this.apiService.postCockpitFeature("/api/Admin/GetFeaturePlanList", data);
  }
  slackNotify(data) {
    return this.apiService.postSlack('/sh_1', data);
  }
  /**
   * leftMenuListService
   */
  leftMenuListService(data) {
    return this.apiService.postFeatured('/api/Workspace/GetMenuList', data);
  }
}
