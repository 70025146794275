<!--/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2021] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : Dashboard Component
///  Description: Billing Dashboard Component
///  Owner      : Sumeet Goel
///  Author     : Aman Kumar and Rohan Goyal
///  Created On : 28-10-2021
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///------------------------------------------------------------------->
<!-- parent div -->
<div class="dashboardBody" *ngIf="!spinner">

  <div  class="parentLoader" *ngIf="isloader">
    <div class="spinner-border text-primary" style="width: 10rem; height: 10rem;" role="status">
      <span class="sr-only"></span>
    </div>
    
  </div>
  <div *ngIf="!isloader">
    <div class=" headerDiv d-flex flex-column">
      <header class="billingsHeader d-flex justify-content-between align-items-center" style="z-index: 10;">
        <div id="wsLogo" class="logosection cursor-pointer" (click)="homepage()">
          <img src="../../../assets/dox2ulogo.svg" />
        </div>
        <div class="logoutSection d-flex align-items-center">
          <div id="homePage" class="logOutTextSection cursor-pointer" (click)="homepage()">
            <img src="../../../assets/img/homeimage.svg" height=" 20px" width="20px" />
          </div>
          <div class="logOutTextSection">
            <p class="cursor-pointer mb-0" (click)="openHelp()">Help & Support</p>
          </div>
          <div class="logOutTextSection">
            <p class=" cursor-pointer mb-0" (click)="logout()">Log out</p>
          </div>
        </div>
      </header>
      <!-- first header end  -->
      <!-- second header -->
      <!-- <div class="secondHeader">
        <div class="d-flex tabsDiv">
          <p (click)="billingTab()" class="tabsDivPara1 mb-0" [ngClass]="activeBorder ? 'billingBorder' : ''">
            Billing
          </p>
          <p (click)="PlanTab()" class="tabsDivPara2 mb-0" [ngClass]="planBorder ? 'plansBorder' : ''">
            Plans
          </p>
        </div>
      </div> -->
      <!-- second header end -->
    </div>
  
    <!-- header end here -->
  
    <!-- body for billing and plans -->
    <div class="padding278">
  
      <!-- billing body -->
      <div class="billingBody  col-12 d-flex flex-column" *ngIf="billingTrue && !plantrue">
        <!-- suspension deletion container -->
        <div *ngIf="workspaceStatus == 'Trial' && trialExpireDays <= 99" class="suspendDiv d-flex justify-content-between align-items-center px-4" style="background-color: #FFF2F2;">
          <div class="d-flex align-items-center" style="column-gap: 24px;">
            <img src="../../../assets/deletionImage.svg">
            <h5 class="mb-0   wsDeltionHead ">WS Suspended due to trial expiry</h5>
          </div>
          <div class="d-flex align-items-center" style="column-gap: 24px;">
            <div class="d-flex flex-column align-items-end">
              <h5 class="mb-0 wsDeltionHead"> {{trialExpireDays}} days</h5>
              <p class="mb-0 paracolor">till deletion</p>
            </div>
            <div>
              <button (click)="PlanTab()" class="subscribeBtn">Subscribe to a plan</button>
            </div>
  
          </div>
        </div>
        <!-- suspension deletion container end -->
        <!-- suspension due to non payment start --> 
        <div *ngIf="latestInvoiceDetails?.apiStatus && workspaceStatus === 'notPaid' && latestInvoiceDetails?.apiStatus !=='success'"  class="suspendDiv d-flex justify-content-between align-items-center px-4" style="background-color: #FFF2F2;" >
          <div class="d-flex align-items-center" style="column-gap: 24px;">
            <img src="../../../assets/deletionImage.svg">
            <div class="d-block">
              <h5 class="mb-0   wsDeltionHead ">WS Suspended due to Non-payment</h5>
              <p class="mb-0 nonPayment">In case of non-payment within the next {{wsSubscriptionRemainDaysCount}} days, your Workspace will be deleted</p>
            </div>
  
          </div>
          <div class="d-flex align-items-center" style="column-gap: 24px;">
            <div class="d-flex flex-column align-items-end">
              <h3 *ngIf="!ifIndianCurrency" class="mb-0 wsDeltionHead">{{InvoiceAmt/100 || 0.00 | currency:'USD'}}</h3>
              <h3 *ngIf="ifIndianCurrency" class="mb-0 wsDeltionHead">{{InvoiceAmt/100 || 0.00 | currency:'INR'}}</h3>
              <!-- <h5 class="mb-0 wsDeltionHead"> {{trialExpireDays}} days</h5> -->
              <p class="mb-0 paracolor">amount due</p>
            </div>
            <div>
              <button (click)="createHostedPageCollectNow()" class="subscribeBtn">Pay now</button>
            </div>
  
          </div>
        </div>
        <!-- suspension due to non payment end -->
        <!-- Grace Period Banner start-->
        <div *ngIf=" latestInvoiceDetails?.apiStatus && workspaceStatus === 'pgp' && latestInvoiceDetails?.apiStatus !=='success'" class="suspendDiv d-flex justify-content-between align-items-center px-4" style="background-color: #FFFDF2;" >
          <div class="d-flex align-items-center" style="column-gap: 24px;">
            <img src="../../../assets/grace.period.svg">
            <div class="d-block">
              <h5 class="mb-0   wsDeltionHead1 ">WS on Payment Grace due to Payment Failure</h5>
              <p class="mb-0 nonPayment1">After Payment Grace period is over your workspace will be Suspended..</p>
            </div>
  
          </div>
          <div class="d-flex align-items-center" style="column-gap: 24px;">
            <div class="d-flex flex-column align-items-end">
              <h5 class="mb-0 wsDeltionHead1"> {{TrialdaysLeft}} days</h5>
              <p class="mb-0 paracolor1">till suspension</p>
            </div>
            <div>
              <button (click)="createHostedPageCollectNow()" class="graceBtn">Pay now</button>
            </div>
  
          </div>
        </div>
         <!-- Grace Period Banner end-->
        <div class="billingParentDiv d-flex d-flex-row justify-content-between">
          <div class="billings d-flex flex-column" style="min-width: 50%">
            <div class="billingsCard1 d-flex flex-column justify-content-center align-items-center" style="background-color: #D9E4FF;">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h4 class=" text-basic mb-2">You are on the <span>{{wsSubscriptionPlan}} Plan</span></h4>
                <p *ngIf="subscriptionType === 'Free' && planUnlimited == true " style=" margin-bottom: 1rem !important;">You have <b>{{remainingwsduration}}</b> days in your trial.</p>
                <p *ngIf="subscriptionType === 'Free' && planUnlimited == true " style="min-width: 90%;">Your expiration date is on <b>{{daysAhead | date: 'longDate'}}</b></p>
                <p *ngIf="subscriptionType === 'Prepaid'" style="min-width: 90%;">Your renewal date is on <b>{{daysAheadO | date: 'longDate'}}</b></p>
  
                <!-- <button *ngIf="subscriptionType !== 'Free' && currentPlanName !== 'Free'" (click)="PlanTab()">MODIFY
                </button> -->
                <button *ngIf="(subscriptionType === 'Prepaid' || (subscriptionType === 'Free' && planUnlimited == false))" class="modify-btn" (click)="PlanTab1()">MODIFY SUBSCRIPTION
                </button>
                <button *ngIf="subscriptionType === 'Free' && planUnlimited == true" class="modify-btn" (click)="PlanTab1()">UPGRADE
                </button>
              </div>
            </div>
            <div class="billingsCard1 d-flex flex-column justify-content-between">
              <h5 style="font-weight: 700; padding-left: 20px; padding-bottom: 12px;">Storage Stats</h5>
              <hr style="margin: 0; color: #ccd6e5">
              <div class="d-flex d-flex-column justify-content-between reorganise" style="padding: 16px 20px">
                <div class="width-concern d-flex d-flex-row justify-content-between" style="margin-left: 1rem; margin-top: 1rem;">
                  <div class="d-flex">
                    <div class="width-concen1">
                      <div class="d-flex">
                        <p class="boldText12">Current Storage</p>
                        <!-- <div class="boldText2">Billing Name</div> -->
                      </div>
                      <hr class="bar-line1">
                      <div class="d-flex" style="margin-top: -0.5rem;">
                        <span class="flow" style="text-align:left;">{{ currentStorageFixed }}<b style="margin-top: 2px; font-weight: 400; font-size: 16px;">{{ currentStorageUnit }}</b></span>
                        <!-- <p class="aligning-text">{{ totalStorageUnit }}</p> -->
                        <!-- <div class="boldText">John Doe</div> -->
                      </div>
                      <div class="d-flex">
                        <p class="boldText12" style="margin-top: 1.5rem">Storage Allowed</p>
                        <!-- <div class="boldText2">Billing Name</div> -->
                      </div>
                      <hr class="bar-line">
                      <div class="d-flex" style="margin-top: -0.5rem;">
                        <span class="flow" style="text-align:left;">{{ unlimitedStorage ? 'Unlimited' : totalStorageFixed }}<b style="margin-top: 2px; font-weight: 400; font-size: 16px;">{{ unlimitedStorage ? '' : totalStorageUnit }}</b></span>
                        <!-- <p class="aligning-text1">{{ currentStorageUnit }}</p> -->
                        <!-- <div class="boldText">John Doe</div> -->
                      </div>
                    </div>
                  </div>
                  <!-- <div class="d-flex">
                    <div class="width-concen1">
                      <div class="d-flex">
                        <p class="boldText12">Current Storage</p>
                       
                      </div>
                      <hr class="bar-line">
                      <div class="d-flex">
                        <span class="flow" style="text-align:left; margin-left: 6px;">{{ currentStorageFixed }}<b style="margin-top: 2px; font-weight: 400; font-size: 16px;">{{ currentStorageUnit }}</b></span>
                        
                      </div>
                    </div>
                  </div> -->
                  <span class="vertical-line">
                  </span>
                </div>

                <div>
                  <span class="horizontal-line"></span>
                </div>
                <div class="d-flex d-flex-column justify-content-between organise">
                  <div class="d-flex d-flex-column justify-content-between organise1">
                    <div class="d-flex" style="align-items: flex-start;  max-height: 100px; width: 100%;">
                      <div class="d-flex">
                        <img src="../../../assets/img/PDF.svg" alt="" style="width: 3.5rem; height: 3.5rem;"/>
                      </div>
                      <div class="d-flex" style="margin-left: 1rem;">
                        <div class="width-concen1">
                          <div class="d-flex" style="max-height: 2rem;">
                            <p class="boldText123">Pdf formats</p>
                           
                          </div>
                          <div class="d-flex">
                            <span class="flow1" style="text-align:left; margin-left: 6px;">{{ pdfConsumedFixed }}<b style="margin-top: 2px; font-weight: 400; font-size: 0.75rem; line-height: 1rem;">{{ pdfConsumedUnit }}</b></span>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="d-flex rework-response">
                      <div class="d-flex">
                        <img src="../../../assets/img/PNG.svg" alt="" style="width: 3.5rem; height: 3.5rem;"/>
                      </div>
                      <div class="d-flex" style="margin-left: 1rem;">
                        <div class="width-concen1">
                          <div class="d-flex" style="max-height: 2rem;">
                            <p class="boldText123">Image formats</p>
                           
                          </div>
                          <div class="d-flex">
                            <span class="flow1" style="text-align:left; margin-left: 6px;">{{ imageConsumedFixed }}<b style="margin-top: 2px; font-weight: 400; font-size: 0.75rem; line-height: 1rem;">{{ imageConsumedUnit }}</b></span>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex d-flex-column justify-content-between organise12">
                    <div class="d-flex organise2">
                      <div class="d-flex">
                        <img src="../../../assets/img/TXT.svg" alt="" style="width: 3.5rem; height: 3.5rem;"/>
                      </div>
                      <div class="d-flex" style="margin-left: 1rem;">
                        <div class="width-concen1">
                          <div class="d-flex" style="max-height: 2rem;">
                            <p class="boldText123">Editable formats</p>
                           
                          </div>
                          <div class="d-flex">
                            <span class="flow1" style="text-align:left; margin-left: 6px;">{{ documentConsumedFixed }}<b style="margin-top: 2px; font-weight: 400; font-size: 0.75rem; line-height: 1rem;">{{ documentConsumedUnit }}</b></span>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <!-- <div class="d-flex organise3">
                      <div class="d-flex">
                        <img src="../../../assets/img/ODS.svg" alt="" style="width: 3.5rem; height: 3.5rem;"/>
                      </div>
                      <div class="d-flex" style="margin-left: 1rem;">
                        <div class="width-concen1">
                          <div class="d-flex" style="max-height: 2rem;">
                            <p class="boldText123">Tabular formats</p>
                           
                          </div>
                          <div class="d-flex">
                            <span class="flow1" style="text-align:left; margin-left: 6px;">{{ currentStorageFixed }}<b style="margin-top: 2px; font-weight: 400; font-size: 0.75rem; line-height: 1rem;">{{ currentStorageUnit }}</b></span>
                            
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </div>
                
                
                
                <!-- <div class="width-concen">
                  <canvasjs-chart [options]="chartOptions" [styles]="{width: '100%', height:'220px'}"></canvasjs-chart>
                </div> -->
               

              </div>
              <!-- <div class="listAmmount d-flex justify-content-between align-items-center "> -->
                <!-- <div class="docnumberheight">
                  <div>
                    <canvasjs-chart [options]="chartOptions" [styles]="{width: '50%', height:'300px'}"></canvasjs-chart>    
                </div>
                </div> -->
                <!-- <div class="d-flex flex-column">
                  <h6 class="mb-2" *ngIf="previousInvoices?.length > 0 && PlanCurrency === 'INR'" style="text-align:center">
                    {{InvoiceAmt/100 | currency:'INR' }}</h6>
                  <h6 class="mb-2" *ngIf="previousInvoices?.length > 0 && PlanCurrency === 'USD'" style="text-align:center">
                    {{InvoiceAmt| currency:'USD' }}</h6>
                  <h6 class="mb-2" *ngIf="previousInvoices?.length === 0" style="text-align:center">--</h6>
                  <button *ngIf="previousInvoices?.length > 0 && latestInvoiceDetails?.apiStatus ==='success'"
                    class="paidBtn">PAID</button>
                    <button *ngIf="previousInvoices?.length > 0 && workspaceStatus === 'pgp' && latestInvoiceDetails?.apiStatus !=='success'"
                      class="paidBtnDue">Payment Due</button>
                      <button
                        class="paidBtnOverDue" *ngIf="previousInvoices?.length > 0 && workspaceStatus === 'notPaid' && latestInvoiceDetails?.apiStatus !=='success'">Payment Overdue</button>
                </div> -->
              <!-- </div> -->
            </div>
            <div class="billingsCard2" style="background-color: white; position: relative;">
              <div class="d-flex align-items-center justify-content-between" style="padding-left: 20px; padding-right: 20px; padding-bottom: 12px;">
                <div class="d-flex flex-column">
                  <h5 style="font-weight:700;font-size: 22px;color: #585858;margin-bottom: 5px;">
                    My dCoins </h5>
                </div>
                <button class="manageBtn" style="height: 2rem;"
                  (click)="PlanTab1()">ADD MORE DCOINS</button>
              </div>
              <hr style="margin: 0; padding: 0; color: #ccd6e5">
              <div class="d-flex boldText" style="align-items: center;padding: 28px 20px 4px;">
                <div>
                  dCoins Balance
                </div>
                <div class="MultipleDocsListsContainerDivHeadCollapseIcon">
                  <div class="boldTextT">
                    {{freeDcoins + purchasedcoins}}
                  </div>
              </div>
              
              </div>
              <div class="MultipleDocsListsContainerDivSubList">
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText3" style="padding-left: 24px">
                    Free dCoins:
                  </p>
                  <p class="boldTexte">
                    {{freeDcoins}}
                  </p>
                </div>
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText3" style="padding-left: 24px">
                    Purchased dCoins:
                  </p>
                  <p class="boldTexte">
                    {{purchasedcoins}}
                  </p>
                </div>
              </div>
              <!-- <div *ngIf="workspaceStatus === 'pgp'"
              class="d-flex align-items-center justify-content-between mt-4">
              <h3 *ngIf="!ifIndianCurrency" class="mb-5"><span class="text-warning">{{dueAmountTotal || 0.00 | currency:'USD'}}</span></h3>
              <h3 *ngIf="ifIndianCurrency" class="mb-5"><span class="text-warning">{{dueAmountTotal || 0.00 | currency:'INR'}}</span></h3>
              <p class="viewBreak mb-5" (click)="openModal(viewBreakdown)">View breakdown</p>
            </div>
            <div *ngIf="workspaceStatus === 'notPaid'"
            class="d-flex align-items-center justify-content-between mt-4">
            <h3 *ngIf="!ifIndianCurrency" class="mb-5"><span class="text-danger">{{dueAmountTotal || 0.00 | currency:'USD'}}</span></h3>
            <h3 *ngIf="ifIndianCurrency" class="mb-5"><span class="text-danger">{{dueAmountTotal || 0.00 | currency:'INR'}}</span></h3>
            <p class="viewBreak mb-5" (click)="openModal(viewBreakdown)">View breakdown</p>
          </div>
              <div *ngIf="workspaceStatus !== 'pgp' && workspaceStatus !== 'notPaid'"
                class="d-flex align-items-center justify-content-between mt-4">
                <h3 *ngIf="!ifIndianCurrency" class="mb-5">{{dueAmountTotal || 0.00 | currency:'USD'}}</h3>
                <h3 *ngIf="ifIndianCurrency" class="mb-5">{{dueAmountTotal || 0.00 | currency:'INR'}}</h3>
                <p *ngIf="subscriptionType !== 'Free' && currentPlanName !== 'Free'" class="viewBreak mb-5"
                  (click)="openModal(viewBreakdown)">View breakdown</p>
              </div>
              
              <div class="pr-4 pl-4 paddingbtm101 d-flex  justify-content-between mb-2 flex-column">
                <div class="d-flex justify-content-between ">
                  <div style="width: 76%;">
                    <input type="text" [(ngModel)]="selectCoupan" (keypress)="getError()" style="outline:none;"
                      [ngClass]="((subscriptionType === 'Free' && clickApply) || (subscriptionType === 'Paid' && clickApply  && blankInput)) ? 'CoupanError' : ''"
                      placeholder="Enter coupon code here">
                  </div>
                  <div>
                    <button class="applyBtn" (click)="applyClick()" *ngIf="!clickApply"> APPLY</button>
                    <button class="applyBtn" (click)="applyClick()" *ngIf="subscriptionType !== 'Free'  && clickApply">
                      APPLY</button>
  
                    <button class="ApplyError" *ngIf="subscriptionType === 'Free' && clickApply " (click)="applyClick()"> APPLY</button>
  
                  </div>
                </div>
                <div style="position: absolute;z-index: 1;bottom: -24px;">
                  <p *ngIf="subscriptionType === 'Free' && clickApply " class="errorMsg px-0 mb-0">Coupon cannot be
                    applied in this plan
                  </p>
                  <p *ngIf="(subscriptionType === 'Paid' && clickApply  && blankInput)"
                    class="errorMsg px-0 mb-0"> Cannot be blank
                  </p>
                </div>
              </div>
   -->
  
  
            </div>
          </div>
          <!-- <div class="billings1 d-flex"> -->
            <div class="billings1ColumnFirst d-flex flex-column" style="min-width: 50%; padding-right: 20px;">
              <!-- first card -->
              <!-- <div class="billings1CardFirst d-flex justify-content-between">
                <div class="cardFirst">
                  <h6 class="pb-1 mb-0">Storage</h6>
                  <div class="line "></div>
                  <div class="d-flex smallCardstorage pt-1">
                    <h4 *ngIf="PlanCurrency === 'USD'" class="mb-0">{{CurrentStorage | number:'1.0':'en-US'}}</h4>
                    <h4 *ngIf="PlanCurrency === 'INR'" class="mb-0"> {{CurrentStorage?.toLocaleString('en-IN')}}</h4>
                    <p class="mb-0">{{unitType}}</p>
                  </div>
  
                </div>
                <div class="greenDiv">
                  <img src="../../../assets/img/book-open.svg">
                </div>
              </div> -->
              <!-- second card -->
              <!-- <div class="billings1CardFirst billings1Cardsecond d-flex justify-content-between">
  
                <div class="cardFirst">
                  <h6 class="pb-1 mb-0">Users</h6>
                  <div class="line1 "></div>
                  <div class="d-flex smallCardstorage pt-1">
                    <h4 class="mb-0">{{numberOfUsers | number:'1.0':'en-US'}}</h4>
                  </div>
  
                </div>
                <div class="pinkDiv">
                  <img src="../../../assets/img/Layer 3.svg">
                </div>
  
              </div> -->
              <!-- third card -->
              <div class="billings1Cardthird d-flex flex-column justify-content-between">
              <h5 class="pl">User Stats</h5>
              <hr style="margin: 0; padding: 0; color: #ccd6e5">
              <div class="d-flex boldText" style="align-items: center;padding: 28px 20px 4px;">
                <div>
                  Total Users Allowed
                </div>
                <div class="MultipleDocsListsContainerDivHeadCollapseIcon">
                  <div class="boldText">
                    {{unlimitedUsers ? 'Unlimited' : freeUsers + addOnUsers}}
                  </div>
              </div>
              
              </div>
              <div class="MultipleDocsListsContainerDivSubList">
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText1" style="padding-left: 24px">
                    Free Users:
                  </p>
                  <p class="boldText">
                    {{unlimitedUsers ? 'Unlimited' : freeUsers}}
                  </p>
                </div>
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText1" style="padding-left: 24px">
                    Add On Users:
                  </p>
                  <div style="display: flex;">
                    <button *ngIf="!unlimitedUsers" style="margin-right: 1rem; max-height: 2rem;" class="manageBtn2" (click)="PlanTab1()">MODIFY</button>
                    <p class="boldText">
                      {{unlimitedUsers ? 'N/A' : addOnUsers}}
                    </p>
                  </div>
                </div>
              </div>
              <hr style="margin: 0px 14px; padding: 0; color: #ccd6e5">
              <div class="d-flex boldText" style="align-items: center;padding: 28px 20px 4px;">
                <div>
                  Current Users
                </div>
                <div class="MultipleDocsListsContainerDivHeadCollapseIcon">
                  <div class="boldText">
                    {{signedUpUsers + invitedUsers}}
                  </div>
              </div>
              
              </div>
              <div class="MultipleDocsListsContainerDivSubList">
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText1" style="padding-left: 24px">
                    Signed Up Users:
                  </p>
                  <p class="boldText">
                    {{signedUpUsers}}
                  </p>
                </div>
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText1" style="padding-left: 24px">
                    Invited Users:
                  </p>
                  <p class="boldText">
                    {{invitedUsers}}
                  </p>
                </div>
              </div>
              <hr style="margin: 0px 14px; padding: 0; color: #ccd6e5">

              <div class="d-flex boldText" style="align-items: center;padding: 28px 20px 4px;">
                <div>
                  Current Tally Viewers
                </div>
                <div class="MultipleDocsListsContainerDivHeadCollapseIcon">
                  <div class="boldText">
                    {{currentTallyViewer}}
                  </div>
              </div>
              
              </div>
              <div class="MultipleDocsListsContainerDivSubList">
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText1" style="padding-left: 24px">
                    Signed Up Tally Viewers:
                  </p>
                  <p class="boldText">
                    {{signedUpTallyViewers}}
                  </p>
                </div>
                <div style="display: flex; justify-content: space-between;padding: 0px 20px;">
                  <p class="boldText1" style="padding-left: 24px">
                    Invited Tally Viewers:
                  </p>
                  <p class="boldText">
                    {{invitedTallyViewer}}
                  </p>
                </div>
              </div>
              <hr style="margin: 0px 14px; padding: 0; color: #ccd6e5">

              <div class="d-flex boldText" style="align-items: center;padding: 28px 20px 4px;">
                <div>
                  Tally Viewers Allowed
                </div>
                <div class="MultipleDocsListsContainerDivHeadCollapseIcon">
                  <div class="boldText">
                    {{totalTallyViewerAllowed}}
                  </div>
              </div>
              
              </div>


              

            </div>
              <!-- <div class="billings1Cardthird d-flex flex-column justify-content-between"
                *ngIf="subscriptionType !== 'Free' && currentPlanName !== 'Free'">
                <h5>Invoice Details</h5>
                <div class="listAmmount d-flex justify-content-between mb-3 align-items-center ">
                  <p class="mb-0">Last invoice amount</p>
                  <div class="d-flex flex-column">
                    <h6 class="mb-2" *ngIf="previousInvoices?.length > 0 && PlanCurrency === 'INR'" style="text-align:center">
                      {{InvoiceAmt/100 | currency:'INR' }}</h6>
                    <h6 class="mb-2" *ngIf="previousInvoices?.length > 0 && PlanCurrency === 'USD'" style="text-align:center">
                      {{InvoiceAmt| currency:'USD' }}</h6>
                    <h6 class="mb-2" *ngIf="previousInvoices?.length === 0" style="text-align:center">--</h6>
                    <button *ngIf="previousInvoices?.length > 0 && latestInvoiceDetails?.apiStatus ==='success'"
                      class="paidBtn">PAID</button>
                      <button *ngIf="previousInvoices?.length > 0 && workspaceStatus === 'pgp' && latestInvoiceDetails?.apiStatus !=='success'"
                        class="paidBtnDue">Payment Due</button>
                        <button
                          class="paidBtnOverDue" *ngIf="previousInvoices?.length > 0 && workspaceStatus === 'notPaid' && latestInvoiceDetails?.apiStatus !=='success'">Payment Overdue</button>
                  </div>
                </div>
                <div class="listAmmount d-flex justify-content-between mb-3 align-items-center ">
                  <p class="mb-0">Next invoice</p>
                  <h6 class="mb-0">{{nextMonth | date:dateFormat}}</h6>
  
                </div>
                <div class=" invoiceList d-flex justify-content-center align-items-center">
                  <p class="cursor-pointer mb-0" (click)="ViewHistory('billing')">
                    See invoice list
                  </p>
                </div>
  
              </div> -->
              <!-- fourth card -->
              <div class="billings1CardFourth d-flex flex-column justify-content-between;">
                <div>
                  <h5 class="pl">Billing Details</h5>
                  <hr style="margin: 0; padding: 0; color: #ccd6e5">
                  <div *ngIf="countryIsAvailable" class="d-flex d-flex-column justify-content-between responsive">
                    <div class="width-concen">
                      <div class="d-flex">
                        <div class="boldText2">Billing Name</div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="boldTextTE">{{ selectedBillingName }}</div>
                      </div>
                      <div class="d-flex">
                        <div class="boldTextTE">{{ displayName }}</div>
                      </div>
                      <div class="d-flex mt-4">
                        <div class="boldText2">Email address</div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="boldTextTE">{{ selectedEmailID }}</div>
                      </div>
                      <div class="d-flex mt-4">
                        <div class="boldText2">Phone number</div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="boldTextTE">{{ selectedPhoneNumber }}</div>
                      </div>
                    </div>
                    
                    <div class="width-concen">
                      <div class="d-flex responsive1">
                        <div class="boldText2">Billing Address</div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="boldTextTE" style="word-break: break-word;">{{ selectedStreet }}</div>
                      </div>
                      <div *ngIf="selectedStreet2 !==''" class="d-flex mt-2">
                        <div class="boldTextTE" style="word-break: break-word;">{{ selectedStreet2 }}</div>
                      </div>
                      <div *ngIf="selectedCity !==''" class="d-flex mt-2">
                        <div class="boldTextTE" style="word-break: break-word;">{{ selectedCity + "," + countryName }}</div>
                      </div>
                      <div *ngIf="selectedPinCode !==''" class="d-flex mt-2">
                        <div class="boldTextTE" style="word-break: break-word;">{{ selectedPinCode }}</div>
                      </div>
                      <div *ngIf="selectedGstNumber !==''" class="d-flex mt-2">
                        <div class="boldTextTE" style="word-break: break-word;">GSTIN: {{ selectedGstNumber }}</div>
                      </div>
                    </div>
                   

                  </div>
                  <div *ngIf="!countryIsAvailable" style="padding: 5rem 6rem 0;">
                    <div class="d-flex d-flex-column" style="flex-direction: column;">
                      <div>
                        <p class="no-content">No billing details updated.</p>
                      </div>
                      <div>
                        <p class="no-content" style="margin-top: -15px;">Please Update your billing details</p>
                      </div>
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <button class="manageBtn1"
                          (click)="PlanTab1()">Add Billing Address</button>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="tableDiv mt-2">
                    <div class="d-flex mb-2 justify-content-between">
                      <div class="d-flex flex-column">
                        <h6>Users</h6>
                        <p *ngIf="!wsInternalPlanName1?.includes('Trial')">Billed monthly</p>
                      </div>
                      <div class="d-flex mb-2 flex-column align-items-end">
                        <h6 *ngIf="!ifIndianCurrency" class="basePlanDoller">{{CurrentPlanUserAmtUSD | currency:'USD'}}
                        </h6>
                        <h6 *ngIf="ifIndianCurrency" class="basePlanDoller">
                          ₹{{CurrentPlanUserAmt?.toLocaleString('en-IN')}}</h6>
                        <p  *ngIf="!wsInternalPlanName1?.includes('Starter') && !wsInternalPlanName1?.includes('Base') && !wsInternalPlanName1?.includes('Trial')">Minimum 5 users </p>
                        <p  *ngIf="!wsInternalPlanName1?.includes('Starter') && wsInternalPlanName1?.includes('Base') && !wsInternalPlanName1?.includes('Trial')">Maximum 1 user </p>
  
                      </div>
                    </div>
                    <div class="d-flex mb-2 justify-content-between">
                      <div class="d-flex flex-column">
                        <h6>Storage</h6>
                      </div>
                      <div class="d-flex mb-2 flex-column align-items-end">
                        <h6 *ngIf="!ifIndianCurrency" class="basePlanDoller">{{dpageCountPerUser}}
                        </h6>
                        <h6 *ngIf="ifIndianCurrency" class="basePlanDoller">
                          {{dpageCountPerUser}}</h6>
                          <p *ngIf="dpageCountPerUser !== 'Unlimited'" >/User</p>
                      </div>
                    </div>
                    <div *ngIf="textExtration" class="d-flex mb-2 justify-content-between">
                      <div class="d-flex flex-column">
                        <h6>Text Extraction Charges</h6>
                        <p >10,000 pages included</p>
                      </div>
                      <div class="d-flex mb-2 flex-column align-items-end">
                        <h6 *ngIf="!ifIndianCurrency" class="basePlanDoller">{{textExtrationChargesUSD | currency:'USD'}}
                        </h6>
                        <h6 *ngIf="ifIndianCurrency" class="basePlanDoller">
                          ₹{{textExtrationCharges?.toLocaleString('en-IN')}}</h6>
                      </div>
                    </div>
  
                  </div> -->
                </div>
  
              </div>
  
            </div>
  
  
            
            <!-- <div class="billings1ColumnSecond d-flex flex-column" style="position:relative;">
              <div class="wsNamediv">
                <div class="wsLOGO mb-3" *ngIf="iconPathname !==null" img condition>
                  <img [src]="iconPath" alt="" class="img-style">
                </div>
                <div class="wsName">
                  <p class="mb-0">
                    {{workspaceName}}
                  </p>
                </div>
              </div>
  
              <hr style=" border: 1px solid rgba(228, 233, 242, 1)" class="my-0">
              <div class="billingDetailDiv d-flex justify-content-between mt-4">
                <div style="width: 70%;">
                  <p class="detailsPara pb-3 mb-0">BILLING DETAILS</p>
                  <div class="detailsDiv">
                    <h6 class="mb-1">{{firstName}}</h6>
                    <p class="mb-1">{{emailId}}</p>
                    <p *ngIf="phoneNumber !== undefined && phoneNumber !== ''" class="mb-1">
                      +{{selectCountCode}}{{phoneNumber}}</p>
                  </div>
                  <p *ngIf="billingDetails?.billing_address?.line1" class="mt-2">{{billingDetails?.billing_address?.line1
                    || ''}} ,
                    {{billingDetails?.billing_address?.line2 || ''}} {{billingDetails?.billing_address?.line3 || ''}}
                    {{billingDetails?.billing_address?.city || ''}}, {{billingDetails?.billing_address?.state || ''}},
                    {{selectCount || ''}}, {{billingDetails?.billing_address?.zip}}</p>
                </div>
                <div *ngIf="subscriptionType !== 'Free' && currentPlanName !== 'Free'" class="editDiv">
                  <p class="cursor-pointer" (click)="openModalBilling(billingAddress, true)">Edit</p>
                </div>
              </div>
              
              <div style="margin-top: 74px;">
                <div *ngIf="false"
                  class=" upgradeCard d-flex flex-column justify-content-center align-items-center text-center">
                  <h5 class="pb-2" *ngIf="priceDetail?.length !== 0">Refer & Save!</h5>
                  <p class="pb-2 mb-0">Refer dox2U to people in your network. Every Workspace created through your referral URL wins you a discount worth $5</p>
                  <p class="pb-2 mb-0"></p>
                  <p class="pb-3 mb-0"><b>Copy the URL & Share </b></p>
                
                    <a href ='https://www.dox2U.com/refer-WS-Name'>https://www.dox2U.com/refer-WS-Name</a>
                </div>
              </div>
              
              <div class="didYouKnowCard m-3 p-3">
                <p class="mb-3 didYouKnowPara">
                  Did you know?
                </p>
                <p class="subtitle-2-para mb-0" style="margin-left: 12px;">
                  {{ currentText }}
                </p>
              </div>
              
            </div> -->
  
          <!-- </div> -->
        </div>
      </div>
      
      <div *ngIf="!billingTrue && plantrue">
        <div class="PlansParentDiv " *ngIf="false">
          <div class=" PlansParent1366 d-flex flex-column container">
  
  
            
  
            <div class="planHeading d-flex flex-column justify-content-center align-items-center">
              <h1>Pricing to suit all sizes of <span>business</span></h1>
              <div class=" planCountry d-flex ">
                <p class="pr-3">Select your billing country to see applicable plans</p>
                <ng-select style="min-width:12rem" [items]="countryList" placeholder="Select Country"
                  [closeOnSelect]="true" bindLabel="country_name" [clearable]="false"
                  (change)="changeCountryForPricing($event)" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="selectedCountryForPricing">
                </ng-select>
              </div>
            </div>
  
  
           
            <div class="d-flex flex-column align-items-center justify-content-center marginT36">
              <div class="d-flex justify-content-center planCardsBody">
               
               
                <div *ngIf=" (planIdSpid  === 7)" class="baseCard cardWidth33">
                  <div style="padding:32px 24px  24px">
                    <div class="cardHead">
                      <h4 class="mb-0">{{PlanNameFree}}</h4>
                    </div>
  
                    <p>Lifetime Free plan for helping small teams get started on their digital journey.</p>
                    <div class="marginb11">
                      <h3>Lifetime free</h3>
                    </div>
                    <div class="marginb22">
                      <p>Includes {{planFreeUser | number:'1.0':'en-US'}} users</p>
                      <p class="cardTooltip"> What if I want more users? <span class="plancardTooltip">You have to upgrade
                          to either Growth or Pro Plan to add more users in your WS</span></p>
                    </div>
                    <button *ngIf="!canDowngrade && planIdSpid === 7" class="disable cardTooltip" disabled>Select Plan
                      <span class="plancardTooltip">You cannot switch to the Base Plan since your current usage is not
                        within applicable limits.
                      </span></button>
                    <button class="cardTooltip" *ngIf="canDowngrade && planIdSpid === 7">Select Plan </button>
                  </div>
                  <div style="padding: 24px">
                    <div class="d-flex flex-column">
  
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Upto 2 users and 5000 dPages</p>
                      </div>
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Document uploads & sharing</p>
                      </div>
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Secure with Audit Logs</p>
                      </div>
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Search & retrieval using content</p>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="growthCard cardWidth33">
                  <div style="padding:32px 24px 24px">
                    <div class="d-flex justify-content-between cardHead">
                      <h4 class="mb-0">{{PlanNameBestSuited}}</h4>
                      <p class="topbutton">No OCR/ICR</p>
                    </div>
                    <p>Best-suited plan for those who want to scale and boost productivity.</p>
                    <div class="d-flex flex-column marginb11">
                      <h3 *ngIf="PlanCurrency === 'INR'">₹{{PlanAmountBestSuitedINR?.toLocaleString('en-IN')}}<span>
                          /month</span></h3>
                      <h3 *ngIf="PlanCurrency === 'USD'">{{PlanAmountBestSuitedUSD | currency:'USD'}}<span> /month</span>
                      </h3>
  
  
  
  
                      <p>plus <span class="dpagesTooltip">Storage
                          <p class="dpagesTooltipPara">Storage charges are calculated on net billable dPages after
                            deducting any free dPage allowance in your plan. Each dPage is equivalent to 500 KB (or 1 page
                            of an A4 sized document scanned at 300 dpi). All our plans offer 2,500 free for each user that
                            signs up to your Workspace!</p>
                        </span> charges</p>
                    </div>
                    <div class="marginb22">
                      <p>Includes {{planBestSuitedUser | number:'1.0':'en-US'}} Free users.
                      </p>
                      <p *ngIf="PlanCurrency === 'INR'"> Add on users:
                        ₹{{PlanUserAmountBestSuitedINR?.toLocaleString('en-IN')}}/month</p>
                      <p *ngIf="PlanCurrency === 'USD'"> Add on users: {{PlanUserAmountBestSuitedUSD |
                        currency:'USD'}}/month</p>
                    </div>
                    <div *ngIf=" PlanCurrency === 'INR' ">
                      <button *ngIf="planIdSpid !== 2 "
                        (click)="selectedPlan('Growth-Plan-INR')">Select Plan</button>
                    </div>
                    <div *ngIf=" PlanCurrency === 'USD' ">
                      <button *ngIf="planIdSpid !== 5  "
                        (click)="selectedPlan('Growth-Plan-USD')">Select Plan</button>
                    </div>
  
                    <button *ngIf="(planIdSpid === 2 && planIdSpid !== 5) &&  PlanCurrency === 'INR'" disabled>Current
                      Plan</button>
                    <button *ngIf="(planIdSpid !== 2 && planIdSpid === 5) &&  PlanCurrency === 'USD'" disabled>Current
                      Plan</button>
  
                  </div>
                  <div style="padding:  24px">
                    <div class="d-flex flex-column">
  
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Secure with encryption</p>
                      </div>
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Granular user access control</p>
                      </div>
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Smart cabinet for easy retrieval</p>
                      </div>
                      <div class="d-flex planCheck" style="margin-bottom:0px ;">
                        <img src="../../../assets/img/check.svg" />
                        <p>Manage deleted trash</p>
                      </div>
                    </div>
                  </div>
                  <hr style="margin-top: 0px;    color: rgba(143, 155, 179, 0.5);">
                  <div style="padding: 0  24px 24px 24px ;">
                    <p class="Text Font/S1 Subtitle mb-0 "
                      style="margin-bottom: 5px !important; color: #585858; font-weight: 600;">Storage charges</p>
                    <p *ngIf="PlanCurrency === 'USD'" class="Text Font/P1 Paragraph">Price for 1,000 dPages / Month: <span
                        class="ml-2" style="color: #585858; font-weight: 600;">{{PageAmtUSDGrowth |
                        currency:'USD'}}</span></p>
                    <p *ngIf="PlanCurrency === 'INR'" class="Text Font/P1 Paragraph">Price for 1,000 dPages / Month: <span
                        class="ml-2"
                        style="color: #585858; font-weight: 600;">₹{{PageAmtINRGrowth?.toLocaleString('en-IN')}}</span>
                    </p>
                  </div>
                </div>
                
                <div class="proCard cardWidth33">
  
                  <div class="bgimageBlue">
                    <div style="position:relative">
                      <div class="cardribbon">
                        <p>Most popular</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between cardHead ">
  
                      <h4 class="colorwhite mb-0">{{PlanNameMostPopular}}</h4>
                      <p class="topbutton colorbgwhite">With OCR/ICR</p>
                    </div>
                    <p class="colorwhite mb-0"> Specially created for teams looking for text extraction (OCR) from Images
                    </p>
  
                    <div class="d-flex flex-column marginb11 colorwhite">
                      <h3 class="colorwhite" *ngIf="PlanCurrency === 'INR'">
                        ₹{{PlanMostPopularSuitedINR?.toLocaleString('en-IN')}}<span class="colorwhite"> /month</span></h3>
                      <h3 class="colorwhite" *ngIf="PlanCurrency === 'USD'"> {{PlanMostPopularSuitedUSD |
                        currency:'USD'}}<span class="colorwhite"> /month</span></h3>
  
  
  
                      <p class="colorwhite">plus <span class="dpagesTooltip">Storage
                          <p class="dpagesTooltipPara">Storage charges are calculated on net billable dPages after
                            deducting any free dPage allowance in your plan. Each dPage is equivalent to 500 KB (or 1 page
                            of an A4 sized document scanned at 300 dpi). All our plans offer 2,500 free dPages for each
                            user that signs up to your Workspace!</p>
                        </span> charges</p>
                      
  
                    </div>
                    <div class="marginb22 ">
                      <p class="colorwhite">Includes {{planPopularSuitedUser | number:'1.0':'en-US'}} Free users.
                      </p>
                      <p class="colorwhite" *ngIf="PlanCurrency === 'INR'"> Add on users:
                        ₹{{PlanUserMostPopularSuitedINR?.toLocaleString('en-IN')}}/month</p>
                      <p class="colorwhite" *ngIf="PlanCurrency === 'USD'"> Add on users:
                        {{PlanUserMostPopularSuitedUSD | currency:'USD'}}/month</p>
  
                    </div>
                    <div *ngIf=" PlanCurrency === 'INR' ">
                      <button *ngIf="planIdSpid !== 3 "
                        (click)="selectedPlan('Pro-Plan-INR')">Select Plan</button>
                    </div>
                    <div *ngIf=" PlanCurrency === 'USD' ">
                      <button *ngIf="planIdSpid !== 6  "
                        (click)="selectedPlan('Pro-Plan-USD')">Select Plan</button>
                    </div>
  
                    <button *ngIf="(planIdSpid === 3 && planIdSpid !== 6) &&  PlanCurrency === 'INR'" disabled>Current
                      Plan</button>
                    <button *ngIf="(planIdSpid !== 3 && planIdSpid === 6) &&  PlanCurrency === 'USD'" disabled>Current
                      Plan</button>
  
                  </div>
                  <div style="padding: 24px ">
                    <div class="d-flex flex-column">
  
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Text extraction from Images</p>
                      </div>
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Downloadable extracted text</p>
                      </div>
                      <div class="d-flex planCheck">
                        <img src="../../../assets/img/check.svg" />
                        <p>Searchable PDFs from Images</p>
                      </div>
                      <div class="d-flex planCheck mb-0">
                        <img src="../../../assets/img/check.svg" />
                        <p>Search & retrieval using content</p>
                      </div>
                    </div>
                  </div>
                  <hr style="margin-top: 0px;    color: rgba(143, 155, 179, 0.5);">
                  <div style="padding: 0  24px 24px 24px ;">
                    <p class="Text Font/S1 Subtitle mb-0 "
                      style="margin-bottom: 5px !important; color: #585858; font-weight: 600;">Storage & Processing
                      charges</p>
                    <p *ngIf="PlanCurrency === 'USD'" class="Text Font/P1 Paragraph">Price for 1,000 dPages / Month: <span
                        class="ml-2" style="color: #585858; font-weight: 600;">{{PageAmtUSDPro | currency:'USD'}}</span>
                    </p>
                    <p *ngIf="PlanCurrency === 'INR'" class="Text Font/P1 Paragraph">Price for 1,000 dPages / Month: <span
                        class="ml-2"
                        style="color: #585858; font-weight: 600;">₹{{PageAmtINRPro?.toLocaleString('en-IN')}}</span></p>
                  </div>
                </div>
  
               
              </div>
              <p style="line-height: 24px; font-weight:600;font-size:15px; color:#585858;" class="my-4">Local taxes (VAT,
                GST, etc.) will be charged in addition to the prices mentioned, where applicable</p>
             
              <div class="freePlanSection">
                <p *ngIf="subscriptionType === 'Free'"
                  class=" cursor-pointer d-flex align-items-center justify-content-center"
                  style="color: rgba(255, 61, 113, 1) !important;font-weight: 600 !important;line-height: 32px !important; font-size: 22px !important; margin-top: 32px;">
                  <span><b
                      style="color: rgba(255, 61, 113, 1) !important;font-weight: 600 !important;line-height: 32px !important; font-size: 22px !important;">Cancel
                      Subscription</b></span>
                </p>
                <div class="d-flex justify-content-center" style="padding-bottom: 33px;"
                  *ngIf="subscriptionType !== 'Free'">
                  <p class="plansection3 mb-0"><span><b> &nbsp; Downgrade to &nbsp; </b></span></p>
                  <p *ngIf="canDowngrade" class="plansection1 cursor-pointer mb-0"><span><b> {{PlanNameFree}} Plan &nbsp;
                      </b></span></p>
  
                  <p *ngIf="!canDowngrade" class=" plansection1 cursor-pointer mb-0 cardTooltipplan" disabled> <b
                      style="color: rgba(143, 155, 179, 0.5) !important;"> {{PlanNameFree}} Plan &nbsp; </b> <span
                      class="plancardTooltipdivplan">You cannot downgrade to the Base Plan since your current usage is not
                      within applicable limits.
                    </span></p>
  
                  <p class="plansection3 mb-0"><span><b> or &nbsp;</b></span></p>
                  <p class="plansection1 cursor-pointer"><span><b> Cancel Subscription &nbsp;</b></span></p>
                </div>
  
  
                <p class="plansection2 mb-0">All our plans include {{dpageFreeAll | number:'1.0':'en-US'}} dPages Free
                  with every signed-up User!
  
                </p>
              </div>
            </div>
  
           
            <div class="d-flex justify-content-between  offersSection">
              <div style="width: 50%;" class="offerdiv1 d-flex   flex-column">
                <h5>Pay only for <span> what you use!</span></h5>
                <p>Our fair usage policy works on daily usage charges! Get charged for the number of users that are
                  present in your Workspace and for the number of <span class="dpagesTooltip">dPages
                    <p class="dpagesTooltipPara">dPages are our unique unit of storage measurement instead of using
                      KB/MB/GB. Each dPage (based on an A4 sized document scanned at 300 dpi) is equivalent to 500 KB.</p>
                  </span> that are stored by you <span style="color: #3366ff;">every day</span>. We
                  bill you on last day of the month, combining your daily usages.</p>
              </div>
              <div style="width: 50%;" class="offerdiv2 d-flex justify-content-center  flex-column">
                <h5>Special <span> offers</span></h5>
                <p>For teams having more than 100 users or storage requirement greater than 100,000 <span
                    class="dpagesTooltip">dPages
                    <p class="dpagesTooltipPara">dPages are our unique unit of storage measurement instead of using
                      KB/MB/GB. Each dPage (based on an A4 sized document scanned at 300 dpi) is equivalent to 500 KB.</p>
                  </span> , contact us for your customized plan.</p>
                <div class=" ">
                  <button class="offerbtn" onclick="location.href='mailto:support@dox2u.com';"
                    (click)="sendSlackNotification()">Contact sales</button>
                </div>
              </div>
            </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
            <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
            <div class="compareBody">
              <div class="compareBodyFirstDiv d-flex justify-content-center align-items-center flex-column">
                <h5>Compare plans</h5>
                <p class="mb-0">Compare plans and choose the one that fits best for your team</p>
              </div>
              <div class="compareSection">
                <div class="d-flex" style="       box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.1);
  position: sticky; top: 0; background-color: white;z-index: 1;">
                  <div class="comparecard1">
                    <p>Request a feature:</p>
                    <p class="contactUS"><a href="mailto:support@dox2u.com" (click)="sendSlackNotification()">Contact
                        us</a></p>
                  </div>
                  <div style="width: 70%;" class="d-flex">
                    <div style="width:33.3% ;position: relative;" class="comparePlanCard">
                      <h4>{{PlanNameFree}}</h4>
                      <!-- <p  *ngIf="PlanCurrency === 'INR'" class="dollerPara"><span> ₹{{PlanUserAmountINR?.toLocaleString('en-IN')}}</span> /month</p>
          <p  *ngIf="PlanCurrency === 'USD'" class="dollerPara"><span> {{PlanUserAmountUSD | currency:'USD'}}</span> /month</p> -->
                      <p class="dollerPara"><span>Free</span></p>
                      <button *ngIf="!canDowngrade && planIdSpid === 7" class=" compareplanBtn disable1" disabled>Select
                        Plan</button>
                      <button *ngIf="canDowngrade && planIdSpid === 7" class=" compareplanBtn disable1" disabled>Select
                        Plan</button>
  
                    </div>
                    <div style="width:33.3%; position: relative;" class="comparePlanCard">
                      <h4>{{PlanNameBestSuited}}</h4>
                      <p *ngIf="PlanCurrency === 'INR'" class="dollerPara"><span>
                          ₹{{PlanAmountBestSuitedINR?.toLocaleString('en-IN')}}</span> /month</p>
                      <p *ngIf="PlanCurrency === 'USD'" class="dollerPara"><span> {{PlanAmountBestSuitedUSD |
                          currency:'USD'}}</span> /month</p>
                      <div *ngIf=" PlanCurrency === 'INR' ">
                        <button class="compareplanBtn" *ngIf="planIdSpid !== 2 "
                          (click)="selectedPlan('Growth-Plan-INR')">Select Plan</button>
                      </div>
                      <div *ngIf=" PlanCurrency === 'USD' ">
                        <button class="compareplanBtn" *ngIf="planIdSpid !== 5  "
                          (click)="selectedPlan('Growth-Plan-USD')">Select Plan</button>
                      </div>
  
                      <button class="compareplanBtn disable1"
                        *ngIf="(planIdSpid === 2 && planIdSpid !== 5) &&  PlanCurrency === 'INR'" disabled>Current
                        Plan</button>
                      <button class="compareplanBtn disable1"
                        *ngIf="(planIdSpid !== 2 && planIdSpid === 5) &&  PlanCurrency === 'USD'" disabled>Current
                        Plan</button>
  
                    </div>
                    <div style="width:33.3%; position: relative; " class="comparePlanCard ">
                      <h4>{{PlanNameMostPopular}}</h4>
                      <p *ngIf="PlanCurrency === 'INR'" class="dollerPara"><span>
                          ₹{{PlanMostPopularSuitedINR?.toLocaleString('en-IN')}}</span> /month</p>
                      <p *ngIf="PlanCurrency === 'USD'" class="dollerPara"><span> {{PlanMostPopularSuitedUSD |
                          currency:'USD'}}</span> /month</p>
                      <div *ngIf="PlanCurrency === 'INR' ">
                        <button class="compareplanBtn" *ngIf="planIdSpid !== 3 "
                          (click)="selectedPlan('Pro-Plan-INR')">Select Plan</button>
                      </div>
                      <div *ngIf=" PlanCurrency === 'USD' ">
                        <button class="compareplanBtn" *ngIf="planIdSpid !== 6  "
                          (click)="selectedPlan('Pro-Plan-USD')">Select Plan</button>
                      </div>
  
                      <button class="compareplanBtn disable1"
                        *ngIf="(planIdSpid === 3 && planIdSpid !== 6) &&  PlanCurrency === 'INR'" disabled>Current
                        Plan</button>
                      <button class="compareplanBtn disable1"
                        *ngIf="(planIdSpid !== 3 && planIdSpid === 6) &&  PlanCurrency === 'USD'" disabled>Current
                        Plan</button>
  
  
  
                    </div>
                  </div>
                </div>
                <!-- tabel -->
                <div class="compareSection">
                  <table class="comparePlansTable">
                    <tr class="centerHeadeing height70">
                      <th class="tableHead tableHead1">
  
                        <!-- <span class="processingTooltip1">Maximum no. of dPages
              
              <p class="processingTooltipPara1">Processing charges are applied one-time for OCR / Text Extraction from Images, calculated for every page of Image processed by our Extraction engine. These are non-recurring charges.</p>
            </span> -->
                        <div class="tooltipdiv">Maximum no. of dPages
                          <p class="tooltiptextdiv">Each dox2U page (dPage) is equivalent to 500 KB, which is the average
                            size of an A4-sized page scanned @ 300dpi. We bill in this convenient unit instead of KB / MB
                            / GB</p>
                        </div>
  
                      </th>
                      <th class="tabledata24">{{planFreedpages | number:'1.0':'en-US'}}/user</th>
                      <th class="tabledata15">Unlimited
                        <br><span>({{planBestSuiteddpages | number:'1.0':'en-US'}} are free)</span>
  
                      </th>
                      <th class="tabledata15">Unlimited
                        <br><span>({{planPopularSuiteddpages | number:'1.0':'en-US'}} are free)</span>
  
                      </th>
                      <!-- <th class="tabledata15">Unlimited
            <br><span>(5,000/user are free)</span>
  
          </th> -->
  
                    </tr>
                    <tr class="centerHeadeing height70">
                      <th class="tableHead tableHead1">Maximum number of users
  
  
                        <!-- <div class="tooltipdiv">
                            <p class="tooltiptextdiv">Processing charges are applied one-time for OCR / Text Extraction from Images, calculated for every page of Image processed by our Extraction engine. These are non-recurring charges.</p>
                          </div> -->
  
                      </th>
                      <th class="tabledata15">{{planFreeUser | number:'1.0':'en-US'}}</th>
                      <th class="tabledata15">{{planBestSuitedUser | number:'1.0':'en-US'}}
  
  
                      </th>
                      <!-- <th class="tabledata15">5 -->
                      <!-- <br><span>(first 5 users free)</span> -->
  
                      <!-- </th> -->
                      <th class="tabledata15">{{planPopularSuitedUser | number:'1.0':'en-US'}}
  
  
                      </th>
  
                    </tr>
  
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">Custom dox2U subdomain for your team
                          <p class="tooltiptextdiv">You can create a custom sub-domain as a specially designated host-name
                            for your team, for example YourName.dox2U.com </p>
                        </div>
  
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"> <img src="../../../assets/img/check.svg"></td>
  
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">Cabinet: Intelligent retrieval
                          <p class="tooltiptextdiv"> Organized view of all documents in your workspace for easy &
                            intuitive retrieval </p>
                        </div>
  
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">Controlled Download, Edit or Print
                          <p class="tooltiptextdiv">Secured access to documents by defining who gets to do what
                          </p>
                        </div>
  
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">Use Tags with templates
                          <p class="tooltiptextdiv"> Add metadata tags using our innovative templates for standardizing
                            how you want your documents structured</p>
                        </div>
  
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
  
                        <div class="tooltipdiv">Extensive Audit and Doc Activity Log
                          <p class="tooltiptextdiv"> All user actions in your workspace are pooled in a central log and
                            records are maintained for even the smallest action on each document
                          </p>
                        </div>
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
  
                        <div class="tooltipdiv">Rights-based sharing of documents
                          <p class="tooltiptextdiv">Secure your information by controlling who gets access to a document
                            by defining your shares</p>
                        </div>
  
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
                        <div class="tooltipdiv">Trash Management
                          <p class="tooltiptextdiv">Avoid those deletions by error and recover deleted files from Trash
                            before they get removed forever from your workspace
                          </p>
                        </div>
  
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
  
                        <div class="tooltipdiv">Data Encryption (both in transit & at rest)
                          <p class="tooltiptextdiv">We use time-tested and highly secure AES-256 and TLS encryptions
                            ensuring your content is secure at all times</p>
                        </div>
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
  
                        <div class="tooltipdiv">Search & Retrieve based on content
                          <p class="tooltiptextdiv">Our deep search uses content for helping you find the precise
                            information you look for
                          </p>
                        </div>
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck d-flex flex-column align-items-center py-3"><img
                          src="../../../assets/img/check.svg">
  
                        <span>(only for non-image formats)</span>
                      </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">Invite users via your team’s domain
                          <p class="tooltiptextdiv">Larger teams can open up access to their workspace by specifying a
                            private domain instead of sending out individual invites to members
                          </p>
                        </div>
                      </td>
                      <td class="imgcentercheck"> </td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">Customized Roles & Rights
                          <p class="tooltiptextdiv">Want to customize your roles & assign specific rights to users? With
                            dox2U you can define your own roles with a few clicks.</p>
                        </div>
                      </td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">Create and Manage User Groups
                          <p class="tooltiptextdiv">Larger teams can organize users in Groups for easier management &
                            administration</p>
                        </div>
                      </td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
  
                        <div class="tooltipdiv">Text extraction from Images
                          <p class="tooltiptextdiv">dox2U uses an internal Parser engine built using advanced AI & OCR
                            capabilities. We don’t share your docs with any external entity!
                          </p>
                        </div>
                      </td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
                        <div class="tooltipdiv">View and Download Extracted Text
                          <p class="tooltiptextdiv"> Text extracted using dox2U Parser engine is available for you to
                            download / copy
                          </p>
                        </div>
  
                      </td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
  
  
                        <div class="tooltipdiv">Searchable PDF from Images
                          <p class="tooltiptextdiv">We convert images into accessible Searchable PDFs that you can
                            download or copy content from</p>
                        </div>
                      </td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                    <tr class="rowheight">
                      <td class="tableHead">
  
  
  
                        <div class="tooltipdiv">Guest Access: Share docs externally
                          <p class="tooltiptextdiv">Avoid sending confidential documents over email. Simply share
                            information with guests not belonging to your workspace and we will manage the rest -
                            securely!
                          </p>
                        </div>
                      </td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"></td>
                      <td class="imgcentercheck"><img src="../../../assets/img/check.svg"></td>
  
                    </tr>
                  </table>
                </div>
                <!-- tabel -->
              </div>
  
            </div>
  
  
  
  
  
  
            <!-- wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww -->
  
  
          </div>
  
  
          <!-- 33333333333333333333333333333333333333 -->
        </div>
        <div class="PlansParentDiv">
          <div class=" PlansParent1366 d-flex flex-column container">
            <div class="planHeading d-flex flex-column justify-content-center align-items-center">
              <h1>Pricing to suit all sizes of <span>business</span></h1>
              <h4 class="headline text-center " style="    font-size: 26px;
              font-weight: 700;
              line-height: 32px;
              letter-spacing: 0em;">Choose a plan</h4>
            </div>
            <div class="d-flex justify-content-center align-items-center cardParentData">
              <div class="p-3 pb-4 baseCard mb-4">
                <div class="cardParent">
                 <div class="baseCard2">
                   <h6 class="headline">Starter</h6>
                   <p class="para1">For small teams looking to get started with a Digital Archive</p>
                   <h6 class="headline headH6 " *ngIf="selectedCountry === 'India'"> &#x20b9;{{starterAmount || 400}}/user/month</h6>
                   <h6 class="headline headH6 "  *ngIf="selectedCountry !== 'India'">${{starterAmountUsd || 6}}/user/month</h6>
                  <p class="para1 headH6">Unlimited user</p>
                 </div>
                 <!-- <div class="cursor-pointer mb-3" *ngIf=""
                 ><button class="" (mouseenter)="starterClick()" (mouseleave)="starterUnClick()" [ngClass]="starterClickVar ? 'planbtnSelected' : 'planbtnStarting' ">TRY FOR FREE</button></div> -->
                 <div class="cursor-pointer mb-3" *ngIf="wsInternalPlanName === 'starter' && subscriptionType == 'Paid'"
                 ><button class="planbtnSelected" >CURRENT PLAN</button></div>
                 <div class="cursor-pointer mb-3"  *ngIf="wsInternalPlanName !== 'starter' "
                 ><button class=""  (mouseenter)="starterClick()" (mouseleave)="starterUnClick()" [ngClass]="starterClickVar ? 'planbtnSelected' : 'planbtnStarting' " (click)="selectedPlan(PlanCurrency === 'INR' ? 'starter-inr' : 'starter-usd')" >SUBSCRIBE</button></div>
                 <div>
                   <div class="height182">
                  
                     <div class="d-flex align-items-center mb-2">
                       <img src="../../../assets/check.svg.svg" alt="" >
                       <p class="subtitle-2 mb-0  " style="margin-left: 8px;">50 GB/user</p>
                     </div>
                     <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                       <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Plugin for Tally</p>
                     </div>
                     <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="">
                       <p class="subtitle-2 mb-0  "  style="margin-left: 8px;">Unlimited Tally Viewers</p>
                     </div>
                     <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="">
                       <p class="subtitle-2 mb-0  "  style="margin-left: 8px;">Audit Log (7 Days)</p>
                     </div>
                     <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="">
                       <p class="subtitle-2 mb-0  "  style="margin-left: 8px;">Smart Cabinet</p>
                     </div>
                   </div>
     
     
     
                 </div>
                 <div class="d-flex tallyLogo">
                  <img src="../../../assets/tallyLogosvg.svg" alt="" >
                  <p class="subtitle-2 mb-0" style="margin-left: 12px;"><span class="text-primary">Learn more </span>the dox2U Tally Plugin</p>
  
                </div>
                </div>
               </div>
               <div class="p-3 pb-4 baseCard mb-4">
                <div class="p-3 pb-4 cardParent">
                  <div class="secondCard2">
                    <div class="d-flex justify-content-between align-items-center teamsPlan">
                      <div class="d-flex justify-content-between align-items-center headWidth">
                        <div class="d-flex">
                          <p class="mb-0 subtitle-2" *ngIf="teamsVal">Switch to Teams</p>
                          <p class="mb-0 subtitle-2" *ngIf="!teamsVal">Switch to Teams Plus</p>
                        <span class="ml-1 infoImg">
                          <img src="../../../assets/infoIcon.svg" alt=""></span>
                        </div>
                        <div class="toggleParent">
                          <label class="toggle-btn">
                            <input type="checkbox" [checked]="!teamsVal" (change)="toggleTeams()">
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <h6 class="headline" *ngIf="teamsVal">Teams Plus</h6>
                    <h6 class="headline" *ngIf="!teamsVal">Teams</h6>
                    <P class="para1">Best-suited plan for those who want to scale and boost productivity</P>
                    <div *ngIf="teamsVal">
                    <h6 class="headline headH6 "  *ngIf="selectedCountry === 'India'">&#x20b9; {{teamsPlusAmount || 850}}/user/month</h6>
                    <h6 class="headline headH6 " *ngIf="selectedCountry !== 'India'">${{teamsPlusAmountUsd || 13}}/user/month</h6>
                    </div>
                    <div *ngIf="!teamsVal">
                    <h6 class="headline headH6 "  *ngIf="selectedCountry === 'India'">&#x20b9; {{teamsPlusAmount || 650}}/user/month</h6>
                    <h6 class="headline headH6 " *ngIf="selectedCountry !== 'India'">${{teamsPlusAmountUsd || 10}}/user/month</h6>
                    </div>
                    <p class="para1 headH6">Minimum 5 user</p>
                    <p class="para1 cursor-pointer" style="text-decoration: underline;" [ngClass]="!teamsVal? 'show1' : 'hide'"><span class="overageTooltips">Overage charges
                      <div class="tooltips">
                        <div class="polygon"></div>
                        This plan includes free text extraction 10,000 pages / month. Any additional pages sent for extraction are billed at ₹150 per 1,000 pages
                      </div>
                    </span></p>
                  </div>
                <div *ngIf="teamsVal">
                  <div class="cursor-pointer mb-3" *ngIf=""
                  ><button class="" (mouseenter)="teamsPlusClick()" (mouseleave)="teamsPlusUnClick()" [ngClass]="teamsPlusClickVar ? 'planbtnSelected' : 'planbtnStarting' ">TRY FOR FREE</button></div>
                  <div class="cursor-pointer mb-3" *ngIf="wsInternalPlanName === 'teams plus' && subscriptionType == 'Paid'"
                  ><button class="planbtnSelected">CURRENT PLAN</button></div>
                  <div class="cursor-pointer mb-3"  *ngIf="wsInternalPlanName !== 'teams plus'"
                  > <button class=""  (mouseenter)="teamsPlusClick()" (mouseleave)="teamsPlusUnClick()" [ngClass]="teamsPlusClickVar ? 'planbtnSelected' : 'planbtnStarting' " (click)="selectedPlan(PlanCurrency === 'INR' ? 'teams-plus-inr' : 'teams-plus-usd')" >SUBSCRIBE</button></div>
                </div>
               <div  *ngIf="!teamsVal">
                <div class="cursor-pointer mb-3" *ngIf=""
                ><button class="" (mouseenter)="teamsClick()" (mouseleave)="teamsUnClick()" [ngClass]="teamsClickVar ? 'planbtnSelected' : 'planbtnStarting' ">TRY FOR FREE</button></div>
                <div class="cursor-pointer mb-3" *ngIf="wsInternalPlanName === 'teams' && subscriptionType == 'Paid'"
                ><button class="planbtnSelected">CURRENT PLAN</button></div>
                <div class="cursor-pointer mb-3"  *ngIf="wsInternalPlanName !== 'teams' "
                ><button class=""  (mouseenter)="teamsClick()" (mouseleave)="teamsUnClick()" [ngClass]="teamsClickVar ? 'planbtnSelected' : 'planbtnStarting' " (click)="selectedPlan(PlanCurrency === 'INR' ? 'teams-inr' : 'teams-usd')"  >SUBSCRIBE</button></div>
               </div>
                  <div>
                    <div class="height182">
                      <p class="subtitle-2 mb-2">
                        Everything in Starter, with
                      </p>
                      <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                        <p class="subtitle-2 mb-0  " style="margin-left: 8px;">100 GB/user </p>
                      </div>
                      <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                        <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Invite Users via Domain</p>
                      </div>
                      <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                        <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Integration APIs</p>
                      </div>
                      <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                        <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Document Preview</p>
                      </div>
                      <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                        <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Custom Roles & Rights</p>
                      </div>
                    </div>
                    <div class="dottedLine mt-3"></div>
                    <div>
                      <p class="subtitle-2 mb-2" *ngIf="teamsVal">
                        Available in Teams Plus
                      </p>
                      <p class="subtitle-2 mb-2" *ngIf="!teamsVal">
                        Available in Teams Plus
                      </p>
                      <div *ngIf="teamsVal">
                        <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                          <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Text extraction from images</p>
                        </div>
                        <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                          <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Deep Search based on content</p>
                        </div>
                        <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                          <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Free extraction for 10,000 pages per month</p>
                        </div>
                      </div>
                     <div *ngIf="!teamsVal">
                      <div class="d-flex align-items-center mb-2">
                        <div class="displayNone"></div>
                        <p class="subtitle-2 mb-0 textdisable ">Text extraction from images</p>
                      </div>
                      <div class="d-flex align-items-center mb-2">
                        <div class="displayNone"></div>
                        <p class="subtitle-2 mb-0  textdisable">Deep Search based on content</p>
                      </div>
                      <div class="d-flex align-items-center mb-2">
                        <div class="displayNone"></div>
                        <p class="subtitle-2 mb-0 textdisable  ">Free extraction for 10,000 pages per month</p>
                      </div>
                     </div>
                    </div>
    
                  </div>
                 </div>
               </div>
             
               <div class="p-3 pb-4 baseCard mb-4">
                <div class="p-3 pb-4 cardParent">
                  <div class="secondCard2">
                    <div class="d-flex justify-content-between align-items-center teamsPlan">
                      <div class="d-flex justify-content-between align-items-center headWidth">
                        <div class="d-flex">
                         <p class="mb-0 subtitle-2" *ngIf="businessVal">Switch to Business</p>
                          <p class="mb-0 subtitle-2" *ngIf="!businessVal">Switch to Business Plus</p>
                        <span class="ml-1 infoImg">
                          <img src="../../../assets/infoIcon.svg" alt="">
                        </span>
                        </div>
                        <div class="toggleParent">
                          <label class="toggle-btn">
                              <input type="checkbox" [checked]="!businessVal" (change)="toggleBusiness()">
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <h6 class="headline" *ngIf="businessVal">Business Plus</h6>
                    <h6 class="headline" *ngIf="!businessVal">Business</h6>
                    <P class="para1">Best-suited plan for those who want to scale and boost productivity</P>
                    <div  *ngIf="businessVal">
                    <h6 class="headline headH6 "  *ngIf="selectedCountry === 'India'">&#x20b9; {{teamsPlusAmount || 1200}}/user/month</h6>
                    <h6 class="headline headH6 " *ngIf="selectedCountry !== 'India'">${{teamsPlusAmountUsd || 18}}/user/month</h6>
                    </div>
                    <div  *ngIf="!businessVal">
                    <h6 class="headline headH6 "  *ngIf="selectedCountry === 'India'">&#x20b9; {{teamsPlusAmount || 1000}}/user/month</h6>
                    <h6 class="headline headH6 " *ngIf="selectedCountry !== 'India'">${{teamsPlusAmountUsd || 15}}/user/month</h6>
                    </div>
                    <p class="para1 headH6">Minimum 5 user</p>
                    <p class="para1 cursor-pointer" style="text-decoration: underline;" [ngClass]="!businessVal? 'show1' : 'hide'"><span class="overageTooltips">Overage charges
                      <div class="tooltips">
                        <div class="polygon"></div>
                        This plan includes free text extraction 10,000 pages / month. Any additional pages sent for extraction are billed at ₹150 per 1,000 pages
                      </div>
                    </span></p>
                  
                  </div>
                  <div *ngIf="businessVal">
                    <div class="cursor-pointer mb-3" *ngIf=""
                    ><button class="" (mouseenter)="businessPlusClick()" (mouseleave)="businessPlusUnClick()" [ngClass]="businessPlusClickVar ? 'planbtnSelected' : 'planbtnStarting' ">TRY FOR FREE</button></div>
                    <div class="cursor-pointer mb-3" *ngIf="wsInternalPlanName === 'bussiness plus' && subscriptionType == 'Paid'"
                    ><button class="planbtnSelected">CURRENT PLAN</button></div>
                    <div class="cursor-pointer mb-3" *ngIf="wsInternalPlanName !== 'bussiness plus' "
                    ><button class=""  (mouseenter)="businessPlusClick()" (mouseleave)="businessPlusUnClick()" [ngClass]="businessPlusClickVar ? 'planbtnSelected' : 'planbtnStarting' "  (click)="selectedPlan(PlanCurrency === 'INR' ? 'business-plus-inr' : 'business-plus-usd')" >SUBSCRIBE</button></div>
                  </div>
                 <div  *ngIf="!businessVal">
                  <div class="cursor-pointer mb-3" *ngIf=""
                  ><button class="" (mouseenter)="businessClick()" (mouseleave)="businessUnClick()" [ngClass]="businessClickVar ? 'planbtnSelected' : 'planbtnStarting' ">TRY FOR FREE</button></div>
                  <div class="cursor-pointer mb-3" *ngIf="wsInternalPlanName === 'bussiness' && subscriptionType == 'Paid'"
                  ><button class="planbtnSelected">CURRENT PLAN</button></div>
                  <div class="cursor-pointer mb-3"  *ngIf="wsInternalPlanName !== 'bussiness'"
                  ><button class=""  (mouseenter)="businessClick()" (mouseleave)="businessUnClick()" [ngClass]="businessClickVar ? 'planbtnSelected' : 'planbtnStarting' " (click)="selectedPlan(PlanCurrency === 'INR' ? 'business-inr' : 'business-usd')"  >SUBSCRIBE</button></div>
                 </div>
                  <div>
                    <div class="height182">
                      <p class="subtitle-2 mb-2">
                        Everything in teams, with
                      </p>
                      <div class="d-flex align-items-center mb-2"><img src="../../../assets/check.svg.svg" alt="" >
                        <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Unlimited Storage</p>
                    </div>
                    <div class="d-flex align-items-center mb-2"><img src="../../../assets/check.svg.svg" alt="" >
                      <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Share Docs with Guests</p>
                    </div>
                    <div class="d-flex align-items-center mb-2"><img src="../../../assets/check.svg.svg" alt="" >
                      <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Dockets </p>
                    </div>
                    </div>
                    <div class="dottedLine mt-3"></div>
                    <div>
                      <p class="subtitle-2 mb-2" *ngIf="businessVal">
                        Available in Business Plus
                      </p>
                      <p class="subtitle-2 mb-2" *ngIf="!businessVal">
                        Available in Business Plus
                      </p>
                      <div *ngIf="businessVal">
                        <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                          <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Text extraction from images</p>
                        </div>
                        <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                          <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Deep Search based on content</p>
                        </div>
                        <div class="d-flex align-items-center mb-2"> <img src="../../../assets/check.svg.svg" alt="" >
                          <p class="subtitle-2 mb-0  " style="margin-left: 8px;">Free extraction for 10,000 pages per month</p>
                        </div>
                      </div>
                     <div *ngIf="!businessVal">
                      <div class="d-flex align-items-center mb-2">
                        <div class="displayNone"></div>
                        <p class="subtitle-2 mb-0 textdisable ">Text extraction from images</p>
                      </div>
                      <div class="d-flex align-items-center mb-2">
                        <div class="displayNone"></div>
                        <p class="subtitle-2 mb-0  textdisable">Deep Search based on content</p>
                      </div>
                      <div class="d-flex align-items-center mb-2">
                        <div class="displayNone"></div>
                        <p class="subtitle-2 mb-0 textdisable  ">Free extraction for 10,000 pages per month</p>
                      </div>
                     </div>
                    </div>
    
                  </div>
                 </div>
               </div>
            </div>
          </div>
        </div>
       
       
       
      </div>
    
      <!-- plans body end -->
  
      <!-- body end for billing and plans -->
  
      <!-- footer -->
      <footer style="background-color: #f7f9fc; padding: 27px 24px 26px" class="footer1366px">
        <div class="d-flex justify-content-center align-items-center" *ngIf="!(!billingTrue && plantrue)">
          <p class="mb-0 ">Plan:</p>
          <p class="mb-0 text-primary mx-2"> {{wsSubscriptionPlan}}</p>
          <p class="mb-0 ">© 2021-2023 Privet Ventures Pvt Ltd. All rights reserved.</p>
        </div>
        <div *ngIf="!billingTrue && plantrue">
          <p class="subtitle text-primary text-center mb-0 cursor-pointer viewText" ><span (click)="viewDetails()"> View detailed comparison</span> </p>
          <p class="subtitle text-danger text-center mb-0 cursor-pointer mt-3 viewDanger" style="height:60px"><span (click)="cancelSubscription()">Cancel Subscription</span></p>
        </div>
        <!-- <input type="datetime-local" id="birthdaytime" name="birthdaytime" [(ngModel)]="term_ends_at">
        <button (click)="changeTermEnd()">apply</button> -->
      </footer>
      <!-- footer end -->
  
  
    </div>
  </div>




</div>
<!-- error handling for Billing Modal -->
<div *ngIf="spinner">
  <div  class="parentLoader">
    <div class="spinner-border text-primary" style="width: 10rem; height: 10rem;" role="status">
      <span class="sr-only"></span>
    </div>
  <!-- <div id="loader" class="row">
    <div class="col-lg-12">
      <div class="box-center">
        <div class="parent-box">
          <div class="boxes">
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>

            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div class="box">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div> -->

</div>
<div *ngIf="false" style="background: linear-gradient(180deg, rgba(51, 102, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);">
  
<div class="d-block justify-content-center align-items-center" style="background: linear-gradient(180deg, rgba(51, 102, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
">
<div style="float:right;position: relative;
right: 2rem;
top: 1rem;">
  <img src="../../../assets/dox2ulogo.svg" alt="">
</div>
<div class="d-flex justify-content-center align-items-center urlNotAvailable">
  <img src="../../../assets//url-not-found.svg" alt="" class="mb-4">
  <p class="urlHead mb-2">
    We're working hard on bringing you the option for paying online for your dox2U Subscription. 
  </p>
  <p style="width: 606px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;">
    In the meantime, please contact <a  href="mailto:billing@dox2u.com" target="_blank"><span class="text-primary">billing@dox2u.com</span></a>  with any subscription modification requests you may have.
  </p>
  <p class="text-center text-primary cursor-pointer urlHead" (click)="redirectedToPricing()">View dox2U's plans</p>
</div>
 
</div>
</div>
<!-- parent div end -->
<!-- delete   modal -->
<div class="deletemodal">
  <ng-template #billingAddress>
    <div>
      <div class="modal-header DeleteModal d-flex justify-content-start paddingclass">
        <p class="modal-title pull-left d-flex align-items-center modalTitle">
          Billing Details
        </p>
      </div>
      <div class="modal-body deletemodalbody paddingclass">
        <p class="">
          The country has been pre-filled based on your selection on the billing
          & subscription Dashboard
        </p>
        <div class="d-flex justify-content-center align-items-center">
          <form #f="ngForm" [formGroup]="phoneForm" class="modalForm" style=" width: 400px ; position: relative;">
            <div class="d-flex formdivInput">
              <!-- first input field -->
              <div style="width: 50%;">
                <input type="text" (paste)="onPasteEmailsignin($event)" placeholder="First name"
                  [(ngModel)]="selectedBillingName" (keypress)="removeSpecialCharacters($event); removeNumber($event)"
                  maxlength="99" [ngModelOptions]="{standalone: true}" />
                <div
                  *ngIf="(selectedBillingName === null || selectedBillingName === undefined || selectedBillingName === '') && editClick"
                  class="billingdetailserror d-flex" style="position: absolute;   width: 50%;
                right: 20px;
                top: 9px; ">
                  <img src="../../../assets/img/error.svg">
                  <span class="tooltipText">This field is mandatory!</span>
                </div>

              </div>
              <!-- second input field -->
              <div style="width:50%">
                <input type="text" placeholder="Last name" [(ngModel)]="selectedBillingNameLast"
                  (keypress)="removeSpecialCharacters($event);removeNumber($event)" maxlength="99"
                  (paste)="onPasteEmailsignin($event)" [ngModelOptions]="{standalone: true}" />
                <!-- <div class="d-flex errorStste" style="       position: absolute;
                right: -138px;
                top: 4px;
                ">
                  <img src="../../../assets/img/error.svg">
                  <span class="">This field is mandatory!</span>
                </div> -->
              </div>
            </div>
            <div>
              <input type="mail" (paste)="onPasteEmailsignin($event)" placeholder="Email address"
                [(ngModel)]="selectedEmailID" (keyup)="checkValid()" maxlength="99"
                [ngModelOptions]="{standalone: true}" />
              <div
                *ngIf="(selectedEmailID === null || selectedEmailID === undefined || selectedEmailID === '') && !emailidValid  && editClick"
                class="errorStste1" style="position: absolute; right: -140px;top: 35px;
              ">
                <img src="../../../assets/img/error.svg">
                <span class="">This field is mandatory!</span>
              </div>
              <div *ngIf="!emailidValid &&  selectedEmailID !== '' && editClick" class="errorStsteInvalid1" style="position: absolute;
              right: -141px;
              top: 35px;
              left: 378px;
          
              ">
                <img src="../../../assets/img/error.svg">
                <span class="">Invalid input</span>
              </div>
            </div>
            <div>
              <ng-select style="min-width:12rem" [items]="countryList" placeholder="Select Country"
                [closeOnSelect]="true" bindLabel="country_name" [clearable]="false" (change)="changeCountry($event)"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCountry">
              </ng-select>
              <div
                *ngIf="(selectedCountry === null || selectedCountry === undefined || selectedCountry === '') && editClick"
                class="errorStste2" style="position: absolute;  right: -137px;top: 66px;
              ">
                <img src="../../../assets/img/error.svg">
                <span class="">This field is mandatory!</span>
              </div>
            </div>
            <div>

              <input type="text" (paste)="onPasteEmailsignin($event)" placeholder="Billing name (Optional)"
                [(ngModel)]="displayName" [ngModelOptions]="{standalone: true}"
                (keypress)="removeSpecialCharacters($event)" maxlength="99" />
              <!-- <div class="errorStste3" style="position: absolute;  right: -137px;top: 99px;
            ">
                <img src="../../../assets/img/error.svg">
                <span class="">This field is mandatory!</span>
              </div> -->
            </div>
            <div>
              <input type="text" (paste)="onPasteEmailsignin($event)" placeholder="Address line 1"
                [(ngModel)]="selectedStreet" (keypress)="removeSpecialCharacters($event)" maxlength="99"
                [ngModelOptions]="{standalone: true}" />
              <div
                *ngIf="(selectedStreet === null || selectedStreet === undefined || selectedStreet === '') && editClick"
                class="errorStste4" style="position: absolute;      right: -136px;
              top: 133px;
              ">
                <img src="../../../assets/img/error.svg">
                <span class="">This field is mandatory!</span>
              </div>
            </div>
            <div>
              <input type="text" (paste)="onPasteEmailsignin($event)" placeholder="Address line 2"
                [(ngModel)]="selectedStreet2" (keypress)="removeSpecialCharacters($event)" maxlength="99"
                [ngModelOptions]="{standalone: true}" />
              <!-- <div class="errorStste5" style="position: absolute; right: -137px;top: 161px;
            ">
                <img src="../../../assets/img/error.svg">
                <span class="">This field is mandatory!</span>
              </div> -->
            </div>
            <div class="d-flex">
              <div style="width: 50%">
                <ng-select *ngIf="stateDropdown " [items]="stateList" placeholder="Select State" [closeOnSelect]="true" 
                  bindLabel="state" [clearable]="false"
                  [class.hide-arrow]="(selectedState === null || selectedState === undefined || selectedState === '') && editClick"
                  (change)="changeState($event)" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedState">
                </ng-select>
                <input *ngIf="!stateDropdown " type="text" placeholder="Select State" [(ngModel)]="selectedState"
                  [ngModelOptions]="{standalone: true}" (keypress)="removeSpecialCharacters($event)" />
                <div
                  *ngIf="(selectedState === null || selectedState === undefined || selectedState === '') && editClick"
                  class="errorStste10" style="position: absolute;
            bottom: 69px;
            width: 100%;
            left: 179px;
              
                  ">
                  <img src="../../../assets/img/error.svg">
                  <span class="">This field is mandatory!</span>
                </div>
                <div>
                  <input type="text" (paste)="onPasteEmailsignin($event)" placeholder="City" [(ngModel)]="selectedCity"
                    (keypress)="removeSpecialCharacters($event)" maxlength="99" [ngModelOptions]="{standalone: true}" />
                  <div *ngIf="(selectedCity === null || selectedCity === undefined || selectedCity === '') && editClick"
                    class="errorStste6" style="    position: absolute;
                  bottom: 37px;
                  width: 100%;
                  left: 179px;
                  z-index: 10;
              
                  ">
                    <img src="../../../assets/img/error.svg">
                    <span class="">This field is mandatory!</span>
                  </div>
                </div>
                <div>
                  <input *ngIf="selectedCountry === 'India'" (paste)="onPasteEmailsignin($event)" type="text"
                    placeholder="GST number" [(ngModel)]="selectedGstNumber" minlength="15" maxlength="15"
                    (keypress)="removeSpecialCharactersGST($event)" [ngModelOptions]="{standalone: true}" />
                  <!-- <div  *ngIf="selectedGstNumber === null || selectedGstNumber === undefined || selectedGstNumber === ''" class="errorStste6" style="position: absolute;
                     bottom: 5px;
                  
                  width: 100%;
                  left: 144px;
                  ">
                    <img src="../../../assets/img/error.svg">
                    <span class="">This field is mandatory!</span>
                  </div> -->
                </div>
              </div>
              <div style="width: 50%">
                <div>
                  <input type="text" (paste)="onPasteEmailsignin($event)"
                    onkeypress='return event.charCode >= 48 && event.charCode <= 57' placeholder="Zip Code"
                    [(ngModel)]="selectedPinCode" (keypress)="removeSpecialCharacters($event)" maxlength="10"
                    [ngModelOptions]="{standalone: true}" />
                  <div
                    *ngIf="(selectedPinCode === null || selectedPinCode === undefined || selectedPinCode === '') && editClick"
                    class="errorStste7" style="position: absolute;    right: -135px;
                  top: 195px;
                  ">
                    <img src="../../../assets/img/error.svg">
                    <span class="">This field is mandatory!</span>
                  </div>
                </div>
                <div>
                  <ngx-intl-tel-input class="flagInput" [cssClass]="''" [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                    [(ngModel)]="selectedPhoneNumber" [selectFirstCountry]="false"
                    [selectedCountryISO]="selectedCountryISO" maxLength="15" [phoneValidation]="true"
                    [separateDialCode]="separateDialCode" [numberFormat]="PhoneNumberFormat.National" name="phone"
                    formControlName="phone" (countryChange)="countryCode($event)">
                  </ngx-intl-tel-input>
                  <div *ngIf="phoneForm.controls['phone'].errors?.required && editClick" class="errorStste8" style="position: absolute;
                    right: -136px;
                    top: 229px; 
                  ">
                    <img src="../../../assets/img/error.svg">
                    <span class="">This field is mandatory!</span>
                  </div>
                  <div
                    *ngIf="phoneForm.controls['phone'].errors && !phoneForm.controls['phone'].errors?.required && editClick"
                    class="errorStste8new" style="    position: absolute;
                    right: -87px;
                    top: 229px;
                  ">
                    <img src="../../../assets/img/error.svg">
                    <span class="">Invalid input!</span>
                  </div>
                </div>
                <div *ngIf="selectedCountry === 'India'">
                  <input type="text" placeholder="PAN" (paste)="onPasteEmailsignin($event)"
                    [(ngModel)]="selectedPanNumber" minlength="10" maxlength="10"
                    (keypress)="removeSpecialCharactersGST($event)" [ngModelOptions]="{standalone: true}" />
                  <!-- <div *ngIf="selectedPanNumber === null || selectedPanNumber === undefined || selectedPanNumber === ''"  class="errorStste9" style="position: absolute; right: -137px;top: 258px;
                  ">
                    <img src="../../../assets/img/error.svg">
                    <span class="">This field is mandatory!</span>
                  </div> -->
                </div>
              </div>

            </div>



          </form>
        </div>
      </div>
      <div class="modal-footer paddingclass d-flex justify-content-start">
        <!-- <div class=" d-flex mt-3">
            <button class="prime-btn" (click)="modalRef.hide()">CONFIRM</button>
            <button class="prime-btn" (click)="modalRef.hide()">CANCEL</button>
          </div> -->
        <span [ngStyle]="{'pointer-events':eventPointer ? 'none' : 'auto' }">
          <button *ngIf="!collectNow && isEditModal" (click)="editDetails(); updateCustomerBillingDetails()" id="next1234"
            class="modalNextbtn" nbButton ghost size="medium" status="primary"><span
              class="button__text">NEXT</span></button></span>

        <span [ngStyle]="{'pointer-events':eventPointer ? 'none' : 'auto' }">
          <button *ngIf="!collectNow && !isEditModal && !haveingSubscription"
            (click)="editDetails(); updateCustomerBillingDetails(); createHostedPage()" id="next1234"
            class="modalNextbtn" nbButton ghost size="medium" status="primary"><span
              class="button__text">NEXT</span></button></span>
        <span [ngStyle]="{'pointer-events':eventPointer ? 'none' : 'auto' }">
          <button *ngIf="!collectNow &&!isEditModal && haveingSubscription"
            (click)="editDetails(); updateCustomerBillingDetails();  createHostedPageUpgrade();" id="next1234"
            class="modalNextbtn" nbButton ghost size="medium" status="primary"><span
              class="button__text">NEXT</span></button></span>
              <span [ngStyle]="{'pointer-events':eventPointer ? 'none' : 'auto' }">
                <button *ngIf="collectNow" (click)="editDetails(); updateCustomerBillingDetails();createHostedPageCollectNow('modal')" id="next1234"
                  class="modalNextbtn" nbButton ghost size="medium" status="primary"><span
                    class="button__text">CNEXT</span></button></span>
        <!-- <button *ngIf="isEditModal" class="modalNextbtn" (click)="editDetails(); updateCustomerBillingDetails()">NEXT</button> -->
        <!-- <button *ngIf="!isEditModal && !haveingSubscription" id="next1234" class="modalNextbtn" (click)="editDetails(); updateCustomerBillingDetails(); createHostedPage();">NEXT</button> -->
        <!-- <button *ngIf="!isEditModal && haveingSubscription" class="modalNextbtn" (click)="editDetails(); updateCustomerBillingDetails();  createHostedPageUpgrade();">NEXT</button> -->
        <button class="modalNextbtn1" (click)="modalRefBilling.hide(); cancelModalBilling();">CANCEL</button>

      </div>
    </div>
  </ng-template>
</div>
<!-- delete modal end  -->
<!-- country change   modal -->
<div class="cuntryChangeModal">
  <ng-template #countrychange>
    <div>
      <div class="modal-header paddingclass d-flex justify-content-start">
        <p class="mb-0">Country changed</p>

      </div>
      <div class="modal_body py-3 px-4">
        <p class="mb-4">You have selected a different country, which may impact the plan selected.

        </p>
        <p> If you choose to proceed you will be redirected to select an alternate plan.</p>
      </div>

      <div class="modal-footer paddingclass d-flex justify-content-start">

        <button class="modalNextbtn" (click)="billingCountryChange();modalRef.hide()">NEXT</button>
        <button class="modalNextbtn1" (click)="modalRef.hide(); CancelCountryChange()">CANCEL</button>

      </div>
    </div>
  </ng-template>
</div>
<!-- country change   modal  end-->
<!-- breakdown modal  -->
<div class="breakdownModal">
  <ng-template #viewBreakdown>
    <div class="viewBreakdowndiv">
      <div class="modal-header DeleteModal d-flex justify-content-start paddingclass">
        <p class="modal-title pull-left d-flex align-items-center modalTitle">
          Billable usage breakdown
        </p>
      </div>
      <div class="modal-body bodybreakdown paddingclass">
        <p class="mb-3">
          View a breakdown of your billable usage and estimated amount due for this billing cycle.
        </p>
        <!-- accordian -->

        <div class="accordion mb-3" id="accordionPanelsStayOpenExample">
          <div class="accordion-item ">
            <h6 class="accordion-header bgcolor py-2 px-3" id="panelsStayOpen-headingOne">
              <div class="d-flex justify-content-between paddingright">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseOne">

                  <p class="mb-0">Users</p>
                  <span *ngIf="ifIndianCurrency">₹{{currentUsageUsers[0]?.total?.toLocaleString('en-IN') ||
                    0.00}}</span>
                  <span *ngIf="!ifIndianCurrency">{{(currentUsageUsers[0]?.total || 0.00) | currency : 'USD'}}</span>
                </button>

              </div>
            </h6>

            <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse "
              aria-labelledby="panelsStayOpen-headingOne">
              <div class="accordion-body accodianOpenBody">

                <table style="width:100%" class="breakdowntable">
                  <tr *ngFor="let users of currentUsageUsers">
                    <td style="text-align: center;">{{users?.CreatedDtm | date:dateFormat}}</td>
                    <td style="text-align: center;">{{users?.PaidUserCount}} users</td>
                    <td style="text-align: center;">
                      <span *ngIf="ifIndianCurrency" >₹{{users?.TotalUserAmt?.toLocaleString('en-IN') || 0.00}}</span>
                      <span *ngIf="!ifIndianCurrency">{{(users?.TotalUserAmt?.total || 0.00) | currency : 'USD'}}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!--   hide -->
        <div *ngIf="(wsInternalPlanName === 'bussiness plus' || wsInternalPlanName === 'teams plus') && subscriptionType == 'Paid'" class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item ">
            <h6 class="accordion-header bgcolor py-2 px-3" id="panelsStayOpen-headingThree">
              <div class="d-flex justify-content-between paddingright">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree">
                  
                  <p class="mb-0">Text Extraction Overage</p>
            
                <span *ngIf="ifIndianCurrency" >₹{{currentUsageParserProcess[0]?.total?.toLocaleString('en-IN') || 0.00}}</span>
                <span *ngIf="!ifIndianCurrency">{{(currentUsageParserProcess[0]?.total || 0.00) | currency : 'USD'}}</span>
                    </button>
              </div>
            </h6>
            <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
              aria-labelledby="panelsStayOpen-headingThree">
              <div class="accordion-body accodianOpenBody">
                <table style="width:100%" class="breakdowntable">
                 <tr *ngFor="let users of currentUsageParserProcess">
                    <td style="text-align: center;">{{users.CreatedDtm | date:dateFormat}}</td>
                    <td style="text-align: center;">{{users.ParserCount}} Pages</td>
                    <td style="text-align: center;">
                      <span *ngIf="ifIndianCurrency" >₹{{users?.PerPageParserAmt?.toLocaleString('en-IN') || 0.00}}</span>
                      <span *ngIf="!ifIndianCurrency">{{(users?.PerPageParserAmt?.total || 0.00) | currency : 'USD'}}</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- accordian -->

      </div>
      <div class="modal-footer  d-flex flex-column p-0">

        <div class="d-flex justify-content-between  py-3 px-4 m-0 totalAmountdiv " style="width: 100%;">
          <p class="mb-0">
            Total amount due
          </p>

          <h6 *ngIf="ifIndianCurrency" class="mb-0">₹{{dueAmountTotal?.toLocaleString('en-IN') ||
            CurrentPlanAmountINR.toLocaleString('en-IN')}}</h6>
          <h6 *ngIf="!ifIndianCurrency" class="mb-0">{{(dueAmountTotal || CurrentPlanAmountUSD )| currency:'USD'}}</h6>
        </div>

        <div style="border-top:1px solid #EDF1F7;height :1px; width: 100%; margin: 0px;" class=""></div>
        <!-- <div class=" d-flex mt-3">
            <button class="prime-btn" (click)="modalRef.hide()">CONFIRM</button>
            <button class="prime-btn" (click)="modalRef.hide()">CANCEL</button>
          </div> -->
        <div class=" py-3 px-4 m-0 d-flex justify-content-end" style="width:100%">
          <button class="modalNextbtn " (click)="modalRef.hide()">OK</button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<div class="deletemodal">
  <ng-template #cancelModal>
    <div class="modal-header DeleteModal d-flex justify-content-start ps-4 p-2">
      <h4 class="modal-title pull-left d-flex align-items-center modalTitle my-3">Cancel Subscription</h4>

    </div>
    <div class="modal-body deletemodalbody">
      <p class="ps-4">You will not have access to your workspace after your subscription is canceled.
        Would you like us to retain your workspace for 15 days (in suspension) or delete it immediately?</p>
      <div class="d-flex ms-4">
        <input type="radio" class="mt-2" checked>
        <p class="pt-2 Upgrade-now">Retain workspace for 15 days</p>
      </div>
      <div class="d-flex ms-4">
        <input type="radio" class="mt-2">
        <p class="pt-2 Upgrade-now">Delete workspace immediately</p>
      </div>

      <form>
        <div class="submit ps-4">
          <div class=" d-flex mt-3">
            <button class="prime-btn" (click)="modalRef.hide()">CONFIRM</button>
            <button class="prime-btn" (click)="modalRef.hide()">CANCEL</button>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>
<!-- new cancel Modal -->
<ng-template #modifyModalNew>
  <div>
    <div class="modal-header DeleteModal d-flex justify-content-start p-4 pt-3 pb-3">
      <h6 class="modal-title pull-left d-flex align-items-center modalTitle pt-1 pb-1">Modify Subscription</h6>
  
    </div>
    <div class="modal-body p-4 pt-3 pb-3">
     <p class="mb-0 subtitle-3">  We're working hard to build up this functionality. </p><br>
     <p class="mb-0 subtitle-3"> Meanwhile, please contact <span class="text-primary"><a href="mailto:support@dox2u.com"  target="_blank">support@dox2u.com</a></span>  to modify your dox2U subscription</p>
    </div>
      <div class="submit newpara p-4 pt-3 pb-3">
        <div class=" d-flex">
          <button class="pr-4 pl-3 okBtn" (click)="dialogueRef.hide()">OK</button>
        </div>
      </div>
  </div>

</ng-template>
<!-- breakdown modal end -->
<!-- modify subscription -->
<ng-template #cancelModalNew>
  <div>
    <div class="modal-header DeleteModal d-flex justify-content-start p-4 pt-3 pb-3">
      <h6 class="modal-title pull-left d-flex align-items-center modalTitle pt-1 pb-1">Cancel Subscription</h6>
  
    </div>
    <div class="modal-body p-4 pt-3 pb-3">
     <p class="mb-0 subtitle-3"> We're sad to see you go :(</p> <br>
     <p class="mb-0 subtitle-3"> Please contact <span class="text-primary"><a href="mailto:support@dox2u.com"  target="_blank">support@dox2u.com</a></span>  to cancel your dox2U subscription</p>
    </div>
      <div class="submit newpara p-4 pt-3 pb-3">
        <div class=" d-flex">
          <button class="pr-4 pl-3 okBtn" (click)="dialogueRef.hide()">OK</button>
        </div>
      </div>
  </div>

</ng-template>
<!-- <ng-template #accountdisabled>
  <nb-card class="promptBody">
    <nb-card-header>
      <div>
        <h6 class="text-basic">{{result[1]}}</h6>
      </div>
    </nb-card-header>
    <nb-card-body class="prompt-body">
      <div>
        <p>
          {{result[0]}}</p>

      </div>


    </nb-card-body>
    <nb-card-footer>
      <div
        *ngIf="result[0] != 'This document may have been moved to trash or permanently deleted therefore cannot be accessed.'">
        <button class="textbutton" (click)="accoutClose(result[2],result[3])">Sign-in</button>
      </div>
      <div
        *ngIf="result[0] == 'This document may have been moved to trash or permanently deleted therefore cannot be accessed.'">
        <button nbButton status="primary" class="textbutton" (click)="accoutClose(result[2],result[3])">Take me to
          dashboard</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template> -->
<div class="deletemodal">
  <ng-template #accountdisabled>
    <div class="wsDetails" style="padding: 24px 24px;">
      <div>
        <h6 class="text-basic"><b>{{result[1]}}</b></h6>
      </div>
    </div>
    <hr style="width:100%; margin-right:16px; margin-top: 0rem; margin-bottom: 0rem;">
    <div class="modal-body deletemodalbody">
      <div>
        <p style="margin-left: 8px;padding: 12px 0px">
          {{result[0]}}</p>
      </div>
      <hr style="width: 107%; margin-left: -24px;">
      <form>
        <div class="submit ps-4">
          <div class="d-flex mt-3" style="margin-left: -8px; ">
            <div
              *ngIf="result[0] != 'This document may have been moved to trash or permanently deleted therefore cannot be accessed.'">
              <button class="docdetailsbtn" (click)="accoutClose(result[2],result[3])">Log Out</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </ng-template>
</div>

 <!--Message Box to display msg for screen less then 768px-->
 <!-- <div  [ngClass]="variable1 ? 'msgBox':'mainAppBox' ">
  <div class="d-flex flex-column align-items-center text-center" style="width:437px;">




  <div class="shadowBoxParent">
    <div 
    class="d-flex justify-content-center align-items-center iconbox">
    <nb-icon icon="monitor-outline" class="text-hint shadowboxicon"></nb-icon>
  </div>
  <div class="shadowBox"  >

  </div>
  </div>





    <p class="Text Font/P1 Paragraph  mt-3" style="padding: 0 20px ;">dox2U currently supports screen resolutions greater than 768px. Your current screen size is not supported and may prevent dox2U from functioning properly.
    </p>
    <button  class="btn_navigate" (click)="navigateDashboard()">PROCEED ANYWAYS</button>
  </div>
</div> -->