/**
 * initialize imports
 */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
/**
 * @ injectable
 */
@Injectable({
  providedIn: 'root'
})
/**
 * export class SharedService
 */
export class SharedService {
/**
 * initialize constructor
 */
  constructor() { }
  /**
 * refresh token
 */
  refreshtoken = new Subject();

}
