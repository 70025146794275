import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SubscriptionDashboardRoutingModule } from './subscription-dashboard-routing.module';
import { SubscriptionDashboardComponent } from './subscription-dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';
import { ChartsModule } from 'ng2-charts';
import { AccumulationChartAllModule, ChartAllModule, RangeNavigatorAllModule } from '@syncfusion/ej2-angular-charts';


@NgModule({
  declarations: [
    SubscriptionDashboardComponent,
    DashboardComponent
  ],
  imports: [
    CommonModule,
    SubscriptionDashboardRoutingModule,
    CanvasJSAngularChartsModule,
    HttpClientModule,
    ChartsModule,
    ChartAllModule, RangeNavigatorAllModule, AccumulationChartAllModule,

    ToastrModule.forRoot(),
    ModalModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule,
    TooltipModule.forRoot(),
NgSelectModule ,ReactiveFormsModule,FormsModule ],
  providers: [BsModalService]
})
export class SubscriptionDashboardModule { }
