import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { AppComponent } from './app.component';
import { TokenInterceptor } from './guard/token.interceptor';
import { SignInService } from './manage-subscription/sign-in.service';
import { PricingService } from './pricing.service';
import { SubscriptionDashboardModule } from './subscription-dashboard/subscription-dashboard.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SubscriptionDashboardModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.baseUrl + '/api/Admin/FrontendLogwrite',
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,

    }),
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right'
     
    }),
  ],
  providers: [CookieService, SignInService, PricingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
