import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
    production: false,
    baseUrl: 'https://dox2udev.com:8443',
    baseUrlFeatured: 'https://dox2udev.com:98',
    baseCockpitURL: 'https://www.dox2udev.com:84',
    backendDatabase : 'WS-',
    baseOrigin: "https://dox2udev.com",
    httpUrl: "https://",
    domain: "dox2udev.com",
    logLevel: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.DEBUG,
    enableSourceMaps: false,
    baseapp: "https://payments.dox2udev.com",
    slackURL: "http://192.168.1.30:8000",
    hostName: 'www.dox2udev.com',
    port: 98,
};