/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2021] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : App Component
///  Description: App Component
///  Owner      : Sumeet Goel
///  Author     : 
///  Created On : 28-10-2021
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///------------------------------------------------------------------->
import { HttpClient } from '@angular/common/http';
import { Component, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { SignInService } from './manage-subscription/sign-in.service';
import { SharedService } from './shared.service';
/**
 * app component metadata
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
 * user local id
   */
  userIdlocal: string | null;
  /**
  *workspace Id local
  */
  wspaceIdlocal: string | null; 
  /**
  *workspace name
  */
  workspaceName: string | null;
  /**
  *billing id of customer
  */
  billingloginId: any;
  /**
 * billing workspace id
  */
  billinglwsid: any;
  /**
  *billing ws URL for querySelector
  */
  billingWSURL: any;
  /**
 * refresh token variable
  */
  refreshokenval: any;
  /**
 * observable subscription var
  */
  pendingRequest: any;
  /**
 * array data
  */
  getarrdata: string[];
  /**
 * url fot sign up
  */
  urlsignup = '';
  login: string;
/**
 * constructor for app component
 */
  constructor(public cookieService: CookieService, public signInService: SignInService, public router: Router,
    private sharedService: SharedService, public route: ActivatedRoute,
    public http: HttpClient) {
    this.workSpaceId = sessionStorage.getItem('billinglwsid');
    this.createdBy = sessionStorage.getItem('billingloginId');
    this.userIdlocal = sessionStorage.getItem('billingloginId');
    this.wspaceIdlocal = sessionStorage.getItem('billinglwsid');
    this.workspaceName= sessionStorage.getItem('billingWSName');
    
    if (this.workSpaceId && this.createdBy) {
      sessionStorage.setItem('sessionbillingloginId', this.createdBy);
      sessionStorage.setItem('sessionbillinglwsid', this.workSpaceId);
    }
    let getstdata = window.location.toString();
    let changereq = getstdata.search('https');
    if (changereq == -1) {
      let newStr = getstdata.replace('http', 'https')
      getstdata = newStr;
      this.getarrdata = getstdata?.split('/');
      if (this.getarrdata[2] != "localhost:4200") {
        if (environment.httpUrl == 'https://') {
          window.location.href = window.location.href.replace('http', 'https')
        }
      }
    }
    this.getarrdata = getstdata?.split('/');

    let splitURLdata = getstdata?.split('/')[4];
    let intermediateURLdata = splitURLdata?.split('?')[0];
    
    if (this.getarrdata[4]) {

      localStorage.setItem('urlredirect', getstdata);
      let getsearchq = getstdata.search('wsId');
      if (getsearchq != -1) {
        var url = new URL(window.location.href);
        var wsId = url.searchParams.get("wsId");
        var loginId = url.searchParams.get('loginId')
       
        localStorage.setItem('param1Billing', wsId);
        localStorage.setItem('param3Billing', loginId);
        if(url.searchParams.get('token')){
          var token = url.searchParams.get('token');
          localStorage.setItem('token',token); 
        }
       
        
      }
      let urlvalue = window.location.href;
      var changeUrl = urlvalue.split('&token');
      var billingValue = changeUrl[0].split('manage-billing/dashboard')
      let makeUrl = 'manage-billing/dashboard' + billingValue[1]

      this.router.navigateByUrl(makeUrl);
    }
  }


  /**
 * Billing title
   */
  title = 'billings-and-payments';
  /**
 * session idle time
 */
  sessionidle: any;
  /**
 * workspace ID 
 */
  workSpaceId: any;
  /**
 * ws created by Email id
 */
  createdBy: any;
  /**
 * end date variable
 */
  enddate: any;
  /**
 * start date variable
 */
  startDate: any;
  /**
*  minutes for session idle time
 */
  minutes: any;
  /**
 * local session idle timer
 */
  minlocaldata: any;
  /**
  * changes data variable
 */
  changesdata: any;
  /**
 * charge bees customerId 
 */
  customerId;
  /**
  * local ws ID
 */
  wsId;
  /**
  * email id variable
 */
  emailId;
  /**
   * git ignore
   */
  ngOnInit(): void {
    this.refreshokenval = this.sharedService.refreshtoken.subscribe((data) => {
      this.refreshtoken();
    });
    sessionStorage.setItem("_expiredTime", JSON.stringify(Date.now()));
    let stdata = window.location.toString();
    let arrdata = stdata.split('/');
    var splitURLdata = stdata.split('/')[4];
    if (this.workSpaceId && this.createdBy) {

      sessionStorage.setItem('sessionbillingloginId', this.createdBy);
      sessionStorage.setItem('sessionbillinglwsid', this.workSpaceId);
    }
    /**
    session management code for checking idle inactive time start here
     */
    setInterval(() => {
      this.sessionidle = JSON.parse(sessionStorage.getItem('sessionidle') || '10');
      this.enddate = JSON.parse(sessionStorage.getItem('_expiredTime') || '');

      this.startDate = Date.now();
      const diff = (this.startDate - this.enddate)
      this.minutes = Math.floor(diff / 60000);
      this.minlocaldata = sessionStorage.setItem('mintues', this.minutes);
      this.changesdata = this.minutes;
      if (this.sessionidle) {
        if (this.changesdata >= this.sessionidle) {
          stdata = window.location.toString();
          arrdata = stdata.split('/');
          splitURLdata = stdata.split('/')[4];
          if (arrdata[4].trim() != 'sign-in') {
            sessionStorage.clear();
            window.open(environment.httpUrl + localStorage.getItem('wsUrl') + '/onboarding/sign-in/billing', '_self');
          }
        }
      }

    }, 15000);
    let intermediateURLdata = splitURLdata?.split('?')[0];
    let self = this;
    /**
     *  auto refresh the session tab
     */
    document.addEventListener("visibilitychange", function () {

      stdata = window.location.toString();
      arrdata = stdata?.split('/');
      splitURLdata = stdata?.split('/')[4];
      intermediateURLdata = splitURLdata?.split('?')[0];
      self.wsId = localStorage.getItem('param1Billing');
      self.emailId = localStorage.getItem('param3Billing');
      let logoinIdCookie = 'localloginId' + localStorage.getItem('param1Billing')
      let wsIdCookie = 'localwsId' + localStorage.getItem('param1Billing')
      if (
        arrdata[4]?.trim() != 'create-workspace' &&
        arrdata[4]?.trim() != 'terms-condiction' &&
        arrdata[3]?.trim() != 'verification' &&
        arrdata[4]?.trim() != 'sign-up' &&
        arrdata[4]?.trim() != 'sign-in' &&
        arrdata[3]?.trim() != 'guest' &&
        arrdata[4]?.trim() != 'change-password-success' &&
        arrdata[4]?.trim() != 'change-password' &&
        arrdata[4]?.trim() != 'disabled' &&
        arrdata[4]?.trim() != 'deleted' &&
        arrdata[4]?.trim() != 'suspended' &&
        arrdata[4]?.trim() != 'error' &&
        arrdata[4]?.trim() != 'sign-out' &&
        arrdata[4]?.trim() != 'NotFoundPage' &&
        intermediateURLdata?.trim() != 'intermediate'
      ) {
        if (sessionStorage.getItem('_expiredTime')) {
          let enddate = JSON.parse(sessionStorage.getItem('_expiredTime'));

          let startDate = Date.now();
          if (enddate) {
            let diff = (startDate - enddate)
            let minutesdata = Math.floor(diff / 60000);

            let sessionidletime = JSON.parse(localStorage.getItem('sessionidle'));

            if (sessionidletime) {
              if (minutesdata >= sessionidletime) {
                window.open(environment.httpUrl + localStorage.getItem('wsUrl') + '/onboarding/sign-in/billing', '_self');
                localStorage.clear();
                sessionStorage.clear();
                self.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
                self.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
                  

              }
            }
            if (self.cookieService.get(logoinIdCookie)) {
              let userIdlocaldoc = self.cookieService.get(logoinIdCookie);
              let wspaceIdlocaldoc = self.cookieService.get(logoinIdCookie);
              if (!userIdlocaldoc || !wspaceIdlocaldoc) {
                window.open(environment.httpUrl + localStorage.getItem('wsUrl') + '/onboarding/sign-in/billing', '_self');
                localStorage.clear();
                sessionStorage.clear();
                  

              }
            }
            else {
              if (!self.cookieService.get(logoinIdCookie)) {
                window.open(environment.httpUrl + localStorage.getItem('wsUrl') + '/onboarding/sign-in/billing', '_self');
                self.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
                self.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
                localStorage.clear();
                sessionStorage.clear();

              }
            }
          }
        }
        else {
          if (!(self.cookieService.get(logoinIdCookie))) {
            window.open(environment.httpUrl + localStorage.getItem('wsUrl') + '/onboarding/sign-in/billing', '_self');
            self.cookieService.delete(logoinIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
            self.cookieService.delete(wsIdCookie, '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
            localStorage.clear();
            sessionStorage.clear();


          }
        }

      }

    });
  }
  /**
   * refresh token if expired
   */
  refreshtoken() {
    let token = {
      jwtToken: sessionStorage.getItem('token'),
      refreshToken: sessionStorage.getItem('billingrefreshtoken')
    }
    this.signInService.refreshtoken(token).subscribe((response: any) => {
      if (response) {
        sessionStorage.setItem('token', response.jwtToken);
        sessionStorage.setItem('billingrefreshtoken', response.refreshToken);
      }
    })

  }
  /**
   * auto sign in from dox2u app nd redirect to dashboard
   */
  signin() {
    let body = {
      "guid": "",
      "str": "",
      "wsLink": this.cookieService.get('wsURL'),
      "emailID": this.cookieService.get('emailId'),
      "password": this.cookieService.get('password'),
      "isEncrypt": 1
    };
    this.pendingRequest = this.signInService.signIn(body)
.subscribe((response: any) => {
      console.log(response);
      if (response.statusCode == 'C200') {
        this.billingloginId = response.returnMessage.split('|')[3];
        this.billinglwsid = response.returnMessage.split('|')[2];
        this.billingWSURL = response.returnMessage.split('|')[4];
        sessionStorage.setItem('billinglwsid', this.billinglwsid);
        sessionStorage.setItem('billingloginId', this.billingloginId);
        sessionStorage.setItem('billingWSURL', this.billingWSURL);
        sessionStorage.setItem('billingPlan', response.returnMessage.split('|')[6]);
        sessionStorage.setItem('customerID', response.returnMessage.split('|')[7]);
        sessionStorage.setItem('token', response.returnMessage.split('|')[8]);
        sessionStorage.setItem('billingrefreshtoken', response.returnMessage.split('|')[9]);
        sessionStorage.setItem('sessionidle', '5');
        this.cookieService.delete('wsURL', '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete('emailId', '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete('password', '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        this.cookieService.delete('isBilling', '/', environment.domain, environment.httpUrl === 'http://' ? false : true, 'Lax');
        window.location.href = '/subscription-dashboard/dashboard';
      }
    })
  }
  /**
   * capture mouse event
  */
  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  /**
  * mouse event capture
   */
  mouseenter() {
    let getmouseenter = JSON.parse(sessionStorage.getItem('mintues') || '{}');
    if (this.sessionidle && localStorage.getItem('wsUrl')) {
      if (getmouseenter >= this.sessionidle) {
        sessionStorage.clear();
        window.open(environment.httpUrl + localStorage.getItem('wsUrl') + '/onboarding/sign-in/billing', '_self');
      } else {
        sessionStorage.setItem("_expiredTime", JSON.stringify(Date.now()));
      }
    }
  }
}
