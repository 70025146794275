import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * 
   * @returns interceptor auth service
   */
  public getToken(): string {
    return localStorage.getItem('token');
  }

  public isAuthenticated(): boolean {
    /**
     * get the token
     */
    const token = this.getToken();
    /**
     * return a boolean reflecting
     * whether or not the token is expired
     */
    return tokenNotExpired(null, token);
  }

  constructor() { }
}
function tokenNotExpired(arg0: null, token: string): boolean {
  throw new Error('Function not implemented.');
}

