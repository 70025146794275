import { Injectable } from '@angular/core';
import { ApiServiceService } from './api-service.service';

@Injectable({
  providedIn: 'root'
})
export class EditDetailsService {

  constructor(private apiService: ApiServiceService) { }
  updateCustomerDetails(data) {
    return this.apiService.post('/api/OrderSummary/updateCustomerDetails', data);
  }
  insertAndUpdate(data) {
    return this.apiService.post('/api/OrderSummary/insertAndUpdate', data);
  }
  CountryList(data) {
    return this.apiService.post('/api/Pricing/CountryList', data);
  }
  StateList(data) {
    return this.apiService.post('/api/Pricing/StateList', data);
  }
}
