import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
/**
 * @ components
 */
@Injectable({
  providedIn: 'root'
})
/**
 *  exporting api service class
 */
export class ApiServiceService {
  /**
 * base origin enviroment variables
 */

  baseOrigin = environment.baseOrigin;
   /**
 * base url enviroment variables
 */

  baseUrl= environment.baseUrl;
   /**
 * base url slack enviroment variables
 */

  baseUrlSlack= environment.slackURL;
   /**
 * base url featured enviroment variables
 */

  baseUrlFeatured= environment.baseUrlFeatured;
     /**
 * base url cockpit enviroment variables
 */

     baseUrlCockpit= environment.baseCockpitURL;
   /**
 * var t declare
 */

  t = '1234567asdfgh';
   /**
 * httpoption
 */

  httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin}) };
   /**
 * http options new with header
 */

  httpOptionsNew = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin, 'Authorization': `Bearer ${this.t}` }) };
 /**
 *initialize constructor
 */

  constructor(private http:HttpClient) { }

   /**
 * post 
 */

  post(url: string,body: any){
    return this.http.post(`${this.baseUrl}${url}`, body, this.httpOptions).pipe(
      retry(3)
    );
  }
   /**
 * post to features
 */

  postFeatured(url: string,body: any){
    return this.http.post(`${this.baseUrlFeatured}${url}`, body, this.httpOptions).pipe(
      retry(3)
    );
  }
     /**
 * post to cockpit features
 */

     postCockpitFeature(url: string,body: any){
      return this.http.post(`${this.baseUrlCockpit}${url}`, body, this.httpOptions).pipe(
        retry(3)
      );
    }
   /**
 *post to slack
 */

  postSlack(url: string,body: any){
    let headers = new Headers();

    headers.append('Content-type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Access-Control-Allow-Origin', this.baseOrigin);
    headers.append('Access-Control-Allow-Credentials', 'true');
    headers.append('Authorization', `Bearer ${this.t}`);
    headers.append('GET', 'POST');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin }) };
    return this.http.post(this.baseUrlSlack + '/sh_1 ', body).pipe(
      retry(3)
    );
    // return this.http.post(`${this.baseUrlSlack}${url}`, body, this.httpOptionsNew).pipe(
    //   retry(3)
    // );
  }
   /**
 * geting values
 */

  get(url:string){
    return this.http.get(`${this.baseUrl}${url}`).pipe(
      retry(3)
    );
  }
 /**
 * postDatawithparams
 */

  postDataWithParams(url:string,body:any,params:any){
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': this.baseOrigin }),params:params };
    return this.http.post(`${this.baseUrl}${url}`, {},httpOptions).pipe(
      retry(3)
    );
  }
}
