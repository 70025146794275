import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { SignInComponent } from './manage-subscription/sign-in/sign-in.component';
import { DashboardComponent } from './subscription-dashboard/dashboard/dashboard.component';

const routes: Routes = [
  {
    path: 'manage-billing',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./subscription-dashboard/subscription-dashboard.module').then(
        (m) => m.SubscriptionDashboardModule
      ),
  },
  {
    path: 'manage-billing/dashboard?wsId=value1&customerId=value2&loginId=value3',
    canActivate: [AuthGuard],
    component: DashboardComponent,
  },
  {
    path: 'manage-billing',
    loadChildren: () =>
      import('./manage-subscription/manage-subscription.module').then(
        (m) => m.ManageSubscriptionModule
      ),
  },
  { path: '', component: SignInComponent, pathMatch: 'full' },
  { path: '**', component: SignInComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
