import { Injectable } from '@angular/core';
import { ApiServiceService } from '../api-service.service';

@Injectable({
  providedIn: 'root',
})
export class SignInService {
  workSpaceId: any;
  createdBy: any;
  // data:any;

  constructor(private apiService: ApiServiceService) {
    this.workSpaceId = sessionStorage.getItem('billinglwsid');
    this.createdBy = sessionStorage.getItem('billingloginId');
  }

  // ****************** SignIn start here *******************
  signIn(data: any) {
    return this.apiService.post('/api/SignIn/SignInValidate', data);
  }
  forgotURLmail(data) {
    return this.apiService.post('/api/SignIn/ForgotURL', data);
  }
  refreshtoken(data) {
    return this.apiService.post('/api/SignIn/RefreshToken', data);
  } 
}
