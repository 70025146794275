<!--/*************************************************************************
 *
 * PRIVET VENTURES - CONFIDENTIAL
 * __________________
 *
 * [2020] - [2021] Privet Ventures Pvt Ltd, India
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Privet Ventures Pvt Ltd and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Privet Ventures Pvt Ltd
 * and its suppliers and may be covered by Indian and Foreign Patents,
 * patents in process, Indian Patents Act 1970, and are protected by secret
 * trade or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Privet Ventures Pvt Ltd.
 */
///-----------------------------------------------------------------
///  Class      : App Component
///  Description: App Component
///  Owner      : Sumeet Goel
///  Author     : 
///  Created On : 28-10-2021
///  Notes      : <Notes>
///  Revision History:
///  Name                Date.            Description
///
///------------------------------------------------------------------->

<div class="content" role="main">
  <title>John Doe - XYZ Pvt Ltd - Billings & Subscriptions</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">


  </div>


<router-outlet></router-outlet>
