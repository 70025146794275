import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DashboardService } from '../dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  /**
  * ws ID 
   */
  wsId: string;
  /**
  * customer ID
 */
  customerId: string;
  /**
* email id
 */
  emailId: string;
  /**
* constructor for auth guard
*/
  constructor(public router: Router, public cookieService: CookieService, public dashboardService: DashboardService) { 
    this.wsId = localStorage.getItem('param1Billing');
    this.emailId = localStorage.getItem('param3Billing');
  }
  /**
* email id local
*/
  userIdlocal: any;
  /**
* ws local id
*/
  wspaceIdlocal: any;
  /**
   * check the user already signed in or not and route is valid
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let login = 'localloginId' + this.wsId;
    let wsid = 'localwsId' + this.wsId;
    this.userIdlocal = this.cookieService.get(login);
    this.wspaceIdlocal = this.cookieService.get(wsid);
    var url = new URL(window.location.href);
    var loginId = url.searchParams.get('loginId');
    if (this.userIdlocal && this.wspaceIdlocal) {
      if (this.userIdlocal === loginId){
        return true;
      } else{
        this.goToFind();
      } 
    }
    else if (!this.userIdlocal || !this.wspaceIdlocal) {
      this.goToFind();
    }
    }
    /**
     * send to find ws or sign in
     */
    goToFind(){
      var url = new URL(window.location.href);
      var wsId = url.searchParams.get("wsId");
      var st = window.location.toString();
      var arr = st.split("/");
      let postData: any
      postData = {
        "WSLink": '',
        "Wsoid": Number(wsId)
      }
      this.dashboardService.wsSubscriptionDetailsService(postData).subscribe((response: any) => {
        if (response.isSuccess) {
          localStorage.setItem('wsUrl',response.data[0].wsLink)
          window.open(environment.httpUrl + response.data[0].wsLink + '/onboarding/sign-in/billing', '_self');
        } else {
          window.location.href = 'https://dox2u.com/find-workspace';
        }
      })
    }
}
