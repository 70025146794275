import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SignInService } from '../sign-in.service';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

/**
 * sign in component
 */
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  /**
  * is sign in failed
  */
  isSignInFailed = false;
    /**
  * billing email id
  */
  billingloginId: any;
    /**
  * disable resend button
  */
  disableResendButton = false;
    /**
  * billing ws id
  */
  billinglwsid: any;
    /**
  * unauthorized Error show
  */
  unauthorizedError = false;
    /**
  * password incorrect error
  */
  passChangeError = false;
    /**
  * not register error
  */
  notRegisteredError = false;
    /**
  * account Disabled Error
  */
  accountDisabledError = false;
    /**
  * reset password Error
  */
  resetpassError = false;
    /**
  * show error or not
  */
  showError = false;
    /**
  * ws name default
  */
  wsName = '';
    /**
  * ws url
  */
  WorkspaceUrl: boolean = false;
    /**
  * password field
  */
  password: boolean = false;
    /**
  * sign in email value
  */
  SignInEmailValue: any;
    /**
  * sign in emial show value
  */
  signinEmailShow: boolean = true;
    /**
  * ws url value
  */
  workspaceurlvalue: any;
    /**
  * http url
  */
  HttpUrl = '';
    /**
  * email value
  */
  Email: any;
    /**
  * email back button
  */ 
  emailback: boolean = false;
    /**
  * is valid email
  */
  ValidMail: boolean = true;
    /**
  * is valid url
  */
  validURL: boolean = true;
    /**
  * is form submitted
  */
  submitted: boolean = false;
    /**
  * is url submitted
  */
  wsurlsubmitted: boolean = false;
    /**
  * is password submitted
  */
  pwdSubmit: boolean = false;
    /**
  * how ws URL
  */
  showWSUrl: boolean = false;
    /**
  * show Password
  */
  showpwd: boolean = false;
    /**
  * show email
  */
  showemail: boolean = true;
    /**
  * email regex pattern
  */
  mailpattern =
    /^[a-z0-9]{1}[a-z0-9._]{0,}\@[a-z0-9]{1}[a-z0-9-]{1,}\.[a-z]{2}[a-zA-Z.]{0,}$/i;
      /**
  * url pattern
  */
  urlpattern = new RegExp('^[a-zA-Z0-9-]*$');
    /**
  * error message logs
  */
  errorMsg: any = null;
    /**
  * error message URL
  */
  errorMsgURL: any = null;
    /**
  * error message password
  */
  errorMsgPassword: any = null;
    /**
  * ws Domain url
  */
  workspaceDomain = '';
    /**
  * href url
  */
  href = '';
    /**
  * reset password path
  */
  resetPath = '/onboarding/reset-password';
    /**
  * interval id
  */
  intervalId;
    /**
  * reset password timer
  */
  timer: boolean;
    /**
  * default password counter
  */
  counter: number;
    /**
  * billing ws URL
  */
  billingWSURL: any;
    /**
  * observable URL pattern subscribe
  */
  pendingRequest: any;
  /**
   * constructor for Sign IN
   */
  constructor(
    public cookieService: CookieService,
    public cd: ChangeDetectorRef,
    public signInService: SignInService,
    public titleService: Title,
    public router: Router,
    public location: Location,
    public http: HttpClient
  ) {
  }
/**
 * git ignore
 */
  ngOnInit(): void {
    window.location.href = 'https://dox2u.com/find-workspace';
    this.HttpUrl = environment.httpUrl;
    this.href = this.router.url;
    var getDomainName = '.';
    var splitDot = new Array();
    var url = window.location.toString();
    var splitBackslash = url.split('/')[2];
    if (splitBackslash == 'localhost:4200') {
      this.workspaceDomain = '.localhost:4200';
    } else {
      splitDot = splitBackslash.split('.');
      for (let i = 1; i < splitDot.length; i++) {
        if (i == 1) getDomainName = getDomainName + splitDot[i];
        if (i == 2) getDomainName = getDomainName + '.' + splitDot[i];
      }
      this.workspaceDomain = getDomainName;
    }
    /**
     * method for when user in password tab and press browser go back button
     */
    this.location.subscribe((value) => {
      this.GoBacToEmail();
    });
  }
  /**
   * back to email
   */
  GoBacToEmail() {
    this.showemail = true;
    this.showWSUrl = false;
    this.showpwd = false;
  }

  passwordIncorrect = false;
  /**
   * On click of confirm, submit email id and password
   */
  signInFormemail = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.mailpattern),
    ]),
  });
  /**
   * change input button state
   */
  changeButtonState() {
    this.errorMsg = null;
    this.errorMsgURL = null;
    this.errorMsgPassword = null;
  }
  /**
   * Email validation
   */
  emailsubmit() {
    this.submitted = true;
    console.log(this.signInFormemail.value);
    if (this.signInFormemail.valid) {
      this.SignInEmailValue = this.signInFormemail.value.email;
      this.location.go('/manage-billing/sign-in');
      let body = {
        guid: '',
        str: '',
        wsLink: '',
        emailID: this.signInFormemail.value.email,
        password: '',
        isEncrypt: 0,
      };
      this.pendingRequest = this.signInService.signIn(body).subscribe((response: any) => {
        console.log(response);
        if (response.statusCode == 'C200') {
          this.signInFormWSURL.reset();
          this.showemail = false;
          this.showWSUrl = true;
        } else if (response.statusCode == 'C400') {
          this.errorMsg =
            'You are not authorized as you are not the owner of any workspace';
          this.unauthorizedError = true;
        } else {
          this.errorMsg = response.returnMessage;
        }
      });
    }
  }
  /**
   * method for wsurl
   */
  signInFormWSURL = new FormGroup({
    WSURL: new FormControl('', [
      Validators.required,
      Validators.pattern(this.urlpattern),
    ]),
  });
  fieldTextType: boolean = false;
  fieldTextTypeRePass: boolean = false;
/**
 * toggle filed input
 */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  /**
   * WS URL validation
   */
  WsUrlsubmit() {
    this.wsurlsubmitted = true;
    if (this.signInFormWSURL.valid) {
      this.workspaceurlvalue = this.signInFormWSURL.value.WSURL;
      let body1 = {
        guid: '',
        str: '',
        wsLink: this.signInFormWSURL.value.WSURL + this.workspaceDomain,
        emailID: '',
        password: '',
        isEncrypt: 0,
      };
      this.pendingRequest = this.signInService.signIn(body1)
.subscribe((response: any) => {
        console.log(response);
        if (response.statusCode == 'C200') {
          this.workspaceurlvalue = this.signInFormWSURL.value.WSURL;
          let body = {
            guid: '',
            str: '',
            wsLink: this.signInFormWSURL.value.WSURL + this.workspaceDomain,
            emailID: this.signInFormemail.value.email,
            password: '',
            isEncrypt: 0,
          };
          this.pendingRequest = this.signInService.signIn(body)
.subscribe((response: any) => {
            console.log(response);
            if (response.statusCode == 'C200') {
              this.signInFormPWD.reset();
              this.showpwd = true;
              this.showWSUrl = false;
              this.cd.detectChanges();
            } else if (response.statusCode == 'C400') {
              this.errorMsgURL =
                'You are not authorized as you are not the owner of this workspace.';
            } else {
              this.errorMsgPassword = response.returnMessage;
            }
          });
        } else if (response.statusCode == 'C400') {
          this.errorMsgURL = 'The URL you entered does not exist.';
        } else {
          this.errorMsgPassword = response.returnMessage;
        }
      });
    }
  }
  /**
   * back navigation
   */
  GoBack() {
    if (this.showpwd && !this.showWSUrl && !this.showemail) {
      this.signInFormWSURL.reset();
      this.wsurlsubmitted = false;
      this.pwdSubmit = false;
      this.showWSUrl = true;
      this.showpwd = false;
      this.signInFormWSURL?.controls?.WSURL?.setErrors(null);
      this.cd.detectChanges();
    } else if (!this.showpwd && this.showWSUrl && !this.showemail) {
      this.showemail = true;
      this.showWSUrl = false;
      this.submitted = false;
      this.wsurlsubmitted = false;
      this.signInFormemail.reset();
      this.signInFormWSURL.reset();
      this.signInFormWSURL?.controls?.email.setErrors(null);
    }
  }

  /**
   * password validation
   */
  signInFormPWD = new FormGroup({
    pwd: new FormControl('', Validators.required),
  });
  PWDsubmit() {
    this.pwdSubmit = true;
    if (this.signInFormPWD.valid) {
      this.workspaceurlvalue = this.signInFormWSURL.value.WSURL;
      let body = {
        guid: '',
        str: '',
        wsLink: this.signInFormWSURL.value.WSURL + this.workspaceDomain,
        emailID: this.signInFormemail.value.email,
        password: this.signInFormPWD.value.pwd,
        isEncrypt: 0,
      };
      this.pendingRequest = this.signInService.signIn(body)
.subscribe((response: any) => {
        console.log(response);
        if (response.statusCode == 'C200') {
          this.showpwd = true;
          this.showWSUrl = false;
          this.billingloginId = response.returnMessage.split('|')[3];
          this.billinglwsid = response.returnMessage.split('|')[2];
          this.billingWSURL = response.returnMessage.split('|')[4];
          sessionStorage.setItem('billingloginId', this.billingloginId);
          sessionStorage.setItem('billinglwsid', this.billinglwsid);
          sessionStorage.setItem('billingWSURL', this.billingWSURL);
          sessionStorage.setItem('billingPlan', response.returnMessage.split('|')[6]);
          sessionStorage.setItem('customerID', response.returnMessage.split('|')[7]);
          sessionStorage.setItem('token', response.returnMessage.split('|')[8]);
          sessionStorage.setItem('billingrefreshtoken', response.returnMessage.split('|')[9]);
          sessionStorage.setItem('sessionidle', '5');
          this.router.navigate(['/subscription-dashboard/dashboard']);
        } else if (response.statusCode == 'C400') {
          this.errorMsgPassword = 'The password you entered is incorrect';
        } else {
          this.errorMsgPassword = response.returnMessage;
        }
      });
    }
  }
  /**
  * go to homepage
  */
  homepage() {
    window.location.href = 'https://dox2u.com/';
  }
  /**
   * forgot URL link invication
   */
  forgotURLmail() {
    this.disableResendButton = true;
    let body = {
      emailId: this.signInFormemail.value.email,
      reSend: '0',
    };
    this.signInService.forgotURLmail(body).subscribe((response: any) => {
      if (response.statusCode == 'C200') {
        console.log('mail sent');
        this.timer = true;

        this.intervalId = setInterval(() => {
          this.counter = this.counter - 1;
          if (this.counter === 0) {
            clearInterval(this.intervalId);
            this.disableResendButton = true;
            this.timer = false;
          }
        }, 1000);
        this.disableResendButton = true;
        setTimeout(() => {
          this.disableResendButton = false;
          this.timer = false;
        }, 15000);
      } else {
        this.timer = true;
        this.intervalId = setInterval(() => {
          this.counter = this.counter - 1;
          if (this.counter === 0) {
            clearInterval(this.intervalId);
            this.disableResendButton = true;
            this.timer = false;
          }
        }, 1000);
        this.disableResendButton = true;
        setTimeout(() => {
          this.disableResendButton = false;
          this.timer = false;
        }, 15000);
      }
    });
  }
}
